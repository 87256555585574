import React from 'react';
import { useTranslation } from 'react-i18next';
import { Section, Image, Content, Title, Description } from './SectionPledge.style';
import { sanitizeTranslation } from '../../../utils/sanitizeTranslation';

const SectionPledge = () => {
  const { t } = useTranslation();
  return (
    <Section>
      <Content>
        <Title>{sanitizeTranslation(t('POPEYE_HOMEPAGE_SUSTAINABILITY_TITLE'))}</Title>
        <Description>{t('POPEYE_HOMEPAGE_SUSTAINABILITY_BODY')}</Description>
      </Content>
      <Image/>
    </Section>
  )
}

export default SectionPledge;

import React, { useEffect, useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { isMobile } from 'react-device-detect';
import clsx from 'clsx';

import Language from './Language/Language';
import MenuItem from './MenuItem/MenuItem';
import BackButtonMobile from '../../elements/BackButtonMobile/BackButtonMobile';

import logo from '../../images/logo.svg';
import logoSmall from '../../images/logo_small.svg';
import logoSmallScrolled from '../../images/logo_small_scrolled.svg';
import logoHome from '../../images/logo_home.svg';
import logoHomeScrolled from '../../images/logo_home_scrolled.svg';
import customer from '../../images/customer.svg';
import customerScrolled from '../../images/customer_scrolled.svg';

import { Wrapper, Menu } from './Header.style';

import {
  SIGNUP_PAGE,
  REGISTER_PAGE,
  REFERRAL_CODE_PAGE,
  VESSEL_PAGE
} from '../../constants/routes';

const Header = ({i18n, location, match, history}) => {
  const { t } = useTranslation();
  const ret = location.pathname.replace(`/${match.params.locale}`, '');
  const isHomePage = match.url === location.pathname;
  const isBackBottonVisible = isMobile && (
    ret === SIGNUP_PAGE ||
    ret === VESSEL_PAGE ||
    ret === REGISTER_PAGE ||
    ret === REFERRAL_CODE_PAGE);

  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScrolled(window.scrollY > 0);
    });
  }, []);

  const onBack = () => {
    if(ret === VESSEL_PAGE || ret === REGISTER_PAGE || ret === REFERRAL_CODE_PAGE) {
      history.goBack()
    } else {
      history.push(`/${match.params.locale}`)
    }
  }

  const getLogo = () => {
    if(isMobile) {
      return <ReactSVG className='header-logo' src={scrolled ? logoSmallScrolled : logoSmall} />;
    } else {
      if(isHomePage) {
        return <ReactSVG className='header-logo' src={scrolled ? logoHomeScrolled : logoHome} />;
      } else {
        return <ReactSVG className='header-logo' src={logo} />;
      }
    }
  }

  const headerClass = clsx({
    'home-page-header': isHomePage,
    'scrolled': scrolled
  })

  return (
    <Wrapper className={headerClass}>
      {getLogo()}
      {isHomePage &&
        <Fragment>
          <Menu>
            <MenuItem desktop to={`${process.env.REACT_APP_POPEYE_URL}/#/${match.params.locale || 'en'}`} label={t('NAVBAR_POPEYE_LABEL')} target="_blank" scrolled={scrolled}/>
            <MenuItem desktop to={`${process.env.REACT_APP_MARKET_URL}/for-boaters/`} label={t('FOR_BOATERS_MENU_ITEM')} target="_blank" scrolled={scrolled}/>
            <MenuItem desktop to={`${process.env.REACT_APP_MARKET_URL}/for-marinas/`} label={t('FOR_MARINAS_MENU_ITEM')} target="_blank" scrolled={scrolled}/>
            <MenuItem desktop to={`${process.env.REACT_APP_MARKET_URL}/for-the-ocean/`} label={t('FOR_THE_OCEAN_MENU_ITEM')} target="_blank" scrolled={scrolled}/>
            <Language i18n={i18n} scrolled={scrolled}/>
            <MenuItem desktop to={`${process.env.REACT_APP_BOATER_URL}/${match.params.locale || 'en'}/login`} label={t('LOG_IN_MENU_ITEM')} target="_blank" mode="white" scrolled={scrolled}/>
            <MenuItem desktop to={`${process.env.REACT_APP_BOATER_URL}/${match.params.locale || 'en'}/registration`} label={t('SIGN_UP_MENU_ITEM')} target="_blank" mode="outlined" scrolled={scrolled}/>
            <MenuItem mobile to={`${process.env.REACT_APP_BOATER_URL}/${match.params.locale || 'en'}/login`} label={<ReactSVG src={scrolled ? customerScrolled : customer} />} target="_blank" scrolled={scrolled}/>
          </Menu>
        </Fragment>
      }
      {Boolean(isBackBottonVisible) && <BackButtonMobile onClick={onBack} />}
    </Wrapper>
  )
}

export default withRouter(Header);

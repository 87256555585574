import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import backgroundImg from '../../../images/home.png';

const backgroundStyles = css`
  background: url(${backgroundImg});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

const flexStyles = css`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Section = styled.section`
  ${flexStyles};
  width: 100%;
  box-sizing: border-box;
  padding: 283px 0 270px;
  ${backgroundStyles};
  @media only screen and (max-width: 767px) {
    padding: 167px 0 124px;
  }
`;

export const Container = styled.div`
  ${flexStyles};
  max-width: 1056px;
  .home-title {
    margin-bottom: 44px;
  }
  @media only screen and (max-width: 991px) {
    padding: 0 40px 0;
  }
  @media only screen and (max-width: 767px) {
    padding: 0 20px 0;
    .home-title {
      margin-bottom: 48px;
    }
  }
  @media only screen and (max-width: 480px) {
    padding: 0;
  }
`;

export const Title = styled.h1`
  height: 100px;
  width: 735px;
  font-size: 33px;
  line-height: 40px;
  color: ${themeGet('text.1', '#ffffff')};
  margin-bottom: 33px;
  @media only screen and (max-width: 767px) {
    height: auto;
    text-align: center;
    margin-bottom: 50px;
  }
  @media only screen and (max-width: 480px) {
    max-width: 328px;
  }
`;

import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;

  img {
    display: block;
    max-width: 584px;
  }
  @media only screen and (max-width: 767px) {
    height: 388px;
    img {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: -60px;
      margin: auto;
    }
  }
`;

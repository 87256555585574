const nameRexp = /^[a-zA-Z][^{0-9}#&@<=>+_\"(|)\\\/ˆ˜'*~.,;:$^%{}±§!?\[\]]+$/;
const emailRexp =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const popeyeInvitationCodeRexp = /^[a-zA-Z0-9]{3,20}/

export const signUpFormSchema = {
  email: {
    placeholder: 'EMAIL_PLACEHOLDER',
    options: {
      required: {
        value: true,
        message: 'EMAIL_REQUIRED'
      },
      pattern: {
        value: emailRexp,
        message: 'INVALID_EMAIL'
      }
    }
  },
  firstName: {
    placeholder: 'FIRST_NAME',
    options: {
      required: {
        value: true,
        message: 'FIRST_NAME_REQUIRED'
      },
      pattern: {
        value: nameRexp,
        message: 'FIRST_NAME_INCORRECT'
      },
      minLength: {
        value: 2,
        message:'FIRST_NAME_LENGTH'
      }
    },
  },
  lastName: {
    placeholder: 'LAST_NAME',
    options: {
      required: {
        value: true,
        message:'LAST_NAME_REQUIRED'
      },
      pattern: {
        value: nameRexp,
        message:'LAST_NAME_INCORRECT'
      },
      minLength: {
        value: 2,
        message:'LAST_NAME_LENGTH'
      }
    }
  },
  password: {
    placeholder: 'PASSWORD_PLACEHOLDER',
    options: {
      required: {
        value: true,
        message:'PASSWORD_REQUIRED'
      },
      minLength: {
        value: 6,
        message:'PASSWORD_LENGTH'
      },
      maxLength: {
        value: 20,
        message:'PASSWORD_TOO_LONG'
      }
    }
  },
  agreement: {
    options: {
      required: {
        value: true,
        message:'SIGN_UP_TERMS_REQUIRED'
      }
    }
  }
}

export const registerFormSchema = {
  invitationCode: {
    placeholder: 'POPEYE_REGISTRATION_INSERT_CODE_PLACE_HOLDER',
    options: {
      pattern: {
        value: popeyeInvitationCodeRexp,
        message: 'INSERT_CODE_ERROR'
      },
    }
  }
}

export const vesselFormSchema = {
  vesselName: {
    placeholder: 'VESSEL_NAME_LABEL',
    options: {
      required: {
        value: true,
        message:'VESSEL_NAME_REQUIRED'
      },
      minLength: {
        value: 3,
        message:'VESSEL_NAME_MIN_LETTERS'
      }
    }
  },
  vesselLength: {
    placeholder: 'LOA_PLACEHOLDER',
    options: {
      required: {
        value: true,
        message:'VESSEL_LOA_REQUIRED'
      },
      min: {
        value: 0.1,
        message:'VESSEL_LENGTH_ERROR'
      },
      max: {
        value: 1000,
        message: 'VESSEL_MAX_LENGTH_ERROR'
      }
    }
  },
  vesselBeam: {
    placeholder: 'BEAM_PLACEHOLDER',
    options: {
      required: {
        value: true,
        message:'VESSEL_BEAM_REQUIRED'
      },
      min: {
        value: 0.1,
        message:'VESSEL_BEAM_ERROR'
      },
      max: {
        value: 1000,
        message: 'VESSEL_MAX_BEAM_ERROR'
      }
    }
  },
  vesselDraft: {
    placeholder: 'DRAFT_PLACEHOLDER',
    options: {
      required: {
        value: true,
        message:'VESSEL_DRAFT_REQUIRED'
      },
      min: {
        value: 0.1,
        message:'VESSEL_DRAFT_ERROR'
      },
      max: {
        value: 1000,
        message: 'VESSEL_MAX_DRAFT_ERROR'
      }
    }
  },
  homeMarinaId: {
    placeholder: 'HOME_MARINA_LABEL',
    options: {
      required: {
        value: true,
        message: 'HOME_MARINA_REQUIRED'
      }
    }
  },
  homeMarinaName: {
    placeholder: 'HOME_MARINA_NAME_LABEL',
    options: {
      required: {
        value: true,
        message: 'HOME_MARINA_REQUIRED'
      },
      pattern: {
        value: nameRexp,
        message: 'FIRST_NAME_INCORRECT'
      },
      minLength: {
        value: 2,
        message:'FIRST_NAME_LENGTH'
      }
    },
  },
}

export const subscribeFormSchema = {
  email: {
    placeholder: 'FOOTER_SUBSCRIBE_PLACEHOLDER',
    options: {
      required: {
        value: true,
        message: 'EMAIL_REQUIRED'
      },
      pattern: {
        value: emailRexp,
        message: 'INVALID_EMAIL'
      }
    }
  }
}

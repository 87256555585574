import React from 'react';
import { Wrapper, Link } from './MenuItem.style.js';

const MenuItem = ({ label, to, target="_self", mode, scrolled, desktop, mobile }) => {
  console.log(scrolled);
  return (
    <Wrapper mode={mode} desktop={desktop} mobile={mobile}>
      <Link href={to} target={target} mode={mode} scrolled={scrolled}>{label}</Link>
    </Wrapper>
  )
}

export default MenuItem;

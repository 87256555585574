import React from 'react';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import { Section, Container, Description } from './SectionDescription.style';

import head from '../../../images/head.svg';

const SectionDescription = () => {
  const { t } = useTranslation();
  return (
    <Section>
      <Container>
        <ReactSVG className="popeye-head" src={head} />
        <Description>{t('POPEYE_HOMEPAGE_DESC')}</Description>
      </Container>
    </Section>
  )
}

export default SectionDescription;

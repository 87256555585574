// icons
import facebookIcon from '../images/facebook_icon.svg';
import instagramIcon from '../images/instagram_icon.svg';
import twitterIcon from '../images/twitter_icon.svg';
import youtubeIcon from '../images/youtube_icon.svg';
import linkedinIcon from '../images/linkedin_icon.svg';

export const getMenuColumns = locale => {
  return [
    {
      title: 'FOOTER_COMPANY',
      items: [
        {
          label: 'FOOTER_ABOUT',
          to: `${process.env.REACT_APP_MARKET_URL}/about/`,
          amplitude: {
            eventProperties: { type: 'about' },
            eventName: 'footer_link_clicked'
          }
        },
        {
          label: 'FOOTER_TEAM',
          to: `${process.env.REACT_APP_MARKET_URL}/about/`,
          amplitude: {
            eventProperties: { type: 'about' },
            eventName: 'footer_link_clicked'
          }
        },
        {
          label: 'FOOTER_CAREER',
          to: `${process.env.REACT_APP_MARKET_URL}/career/`,
          amplitude: {
            eventProperties: { type: 'career' },
            eventName: 'footer_link_clicked'
          }
        }
      ]
    },
    {
      title: 'FOOTER_SOLUTIONS',
      items: [
        {
          label: 'FOOTER_POPEYE',
          to: `${process.env.REACT_APP_POPEYE_URL}/#/${locale || 'en'}`,
          amplitude: {
            eventProperties: { source: 'footer' },
            eventName: 'poopeye_lp_clicked'
          }
        },
        {
          label: 'FOOTER_MARINAS',
          to: `${process.env.REACT_APP_MARKET_URL}/for-marinas/`,
          amplitude: {
            eventProperties: { source: 'footer' },
            eventName: 'for_marinas_clicked'
          }
        },
        {
          label: 'FOOTER_BOATERS',
          to: `${process.env.REACT_APP_MARKET_URL}/for-boaters/`,
          amplitude: {
            eventProperties: { source: 'footer' },
            eventName: 'for_boaters_clicked'
          }
        },
        {
          label: 'FOOTER_OCEAN',
          to: `${process.env.REACT_APP_MARKET_URL}/for-the-ocean/`,
          amplitude: {
            eventProperties: { source: 'footer' },
            eventName: 'for_ocean_clicked'
          }
        }
      ]
    },
    {
      title: 'FOOTER_RESOURCES',
      items: [
        {
          label: 'FOOTER_BLOG',
          to: `${process.env.REACT_APP_MARKET_URL}/blog/`,
          amplitude: {
            eventProperties: { type: 'blog' },
            eventName: 'footer_link_clicked'
          }
        },
        {
          label: 'FOOTER_PRESS',
          to: `${process.env.REACT_APP_MARKET_URL}/press/`,
          amplitude: {
            eventProperties: { type: 'press' },
            eventName: 'footer_link_clicked'
          }
        },
        {
          label: 'FOOTER_CONTACT',
          to: `${process.env.REACT_APP_MARKET_URL}/contact/`,
          amplitude: {
            eventProperties: { type: 'contact' },
            eventName: 'footer_link_clicked'
          }
        }
      ]
    }
  ]
}

export const socialMenuItems = [
  {
    icon: instagramIcon,
    url: 'https://www.instagram.com/pickapier/',
    amplitude: {
      eventProperties: { type: 'Instagram' },
      eventName: 'footer_link_clicked'
    }
  }, {
    icon: facebookIcon,
    url: 'https://www.facebook.com/Pickapier/',
    amplitude: {
      eventProperties: { type: 'facebook' },
      eventName: 'footer_link_clicked'
    }
  }, {
    icon: linkedinIcon,
    url: 'https://www.linkedin.com/company/pick-a-pier/',
    amplitude: {
      eventProperties: { type: 'linkedin' },
      eventName: 'footer_link_clicked'
    }
  }, {
    icon: twitterIcon,
    url: 'https://twitter.com/pickapier?lang=en',
    amplitude: {
      eventProperties: { type: 'twitter' },
      eventName: 'footer_link_clicked'
    }
  }, {
    icon: youtubeIcon,
    url: 'https://www.youtube.com/channel/UCXUakkH_x3N_Ky3436xHqsw',
    amplitude: {
      eventProperties: { type: 'youtube' },
      eventName: 'footer_link_clicked'
    }
  }
]
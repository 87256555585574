import React from 'react';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import { Section, Advantages, Advantage, Text } from './SectionAdvantages.style';

import { advantages } from '../../../constants/advantages';

const SectionAdvantages = () => {
  const { t } = useTranslation();
  return (
    <Section>
      <Advantages>
        {advantages.map(({icon, text}, index) => (
          <Advantage key={index}>
            <ReactSVG src={icon} />
            <Text>{t(text)}</Text>
          </Advantage>
        ))}
      </Advantages>
    </Section>
  )
}

export default SectionAdvantages;

import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const Button = styled.button`
  background: transparent;
  color: ${themeGet('text.7', '#121A2C')};
  line-height: 34px;
  font-size: 19px;
  padding: 10px 51px;
  height: 54px;
  transition: all 0.3s ease;
  outline: none;
  &:hover,
  &:active,
  &:target {
    color: ${themeGet('color.4', '#0036b1')};
  }
  @media only screen and (max-width: 767px) {
    color: ${themeGet('text.1', '#ffffff')};
    &:hover,
    &:active,
    &:target {
      color: ${themeGet('color.2', '#5AD8CC')};
    }
  }
`;

const csv = require('csv-parser')
const fs = require('fs')
const writeFrom = process.argv.slice(2,4)[0]
const writeTo = process.argv.slice(2,4)[1]

if (!writeFrom || !writeTo) {
  process.exit(1)
}

var diff = require('deep-diff');
var languages = require('./index');

var exportMissingTranlations = () => {
  if (writeTo === 'en.js') return; // theres no point diff from en.js to en.js
  const fromLang = languages['en']
  const toLang = languages[writeTo.split('.')[0]]
  var content = 'code,translation,\n';
  var d;
  var subclass = '';
  for (d of diff(fromLang, toLang)) {
    if (d.kind !== 'E') {
      if (d.kind === 'D') {
        if (subclass !== d.path[0]) {
        if (!d.path[1]) {
          for (const [ key, value ] of Object.entries(d.lhs)) {
            const row = `${key}:, ${value.replace(/\n|\r/g, "").replace(/,/g, '|')}\n`
            content += row;
          }
        } else {
          const row = `${d.path[1]}:, ${d.lhs.replace(/\n|\r/g, "").replace(/,/g, '|')}\n`
          content += row;
        }
      }
    }
  }
};
  fs.writeFileSync(`./hosarim/${writeTo.split('.')[0]}_hosarim.csv`, content, (err) => {
    console.log(err, `Couldn't write to file ${writeTo}`)
  } ) 
}

exportMissingTranlations()

const onlyHosarim = writeFrom === 'hosarim';
if (onlyHosarim) process.exit(1);

const oldFile = languages[writeTo.split('.')[0]];

let beginingOfFile = `module.exports =  {
  translation: { \n`
  let fileContent = beginingOfFile;

fs.createReadStream(`./hosarim/${writeFrom}`)
  .pipe(csv({separator: '\t'}))
  .on('data', (data) => {
    const code = data['0'] ? data['0'].trim() : ''
    const translation = data['1'] ? data['1'].trim().replace(/\'/g, '\\\'').replace(/\n|\r/g, "") : ''
    const row = data['0'] && data['1'] ? `\t\t'${code.replace(':', '\':')} '${translation}',\n` : ''
    fileContent += row;
  })
  .on('end', () => {
    let endOfFile = `\t}
};`
  fileContent += endOfFile;
  fs.writeFileSync(writeTo, fileContent, (err) => {
    console.log(err, `Couldn't write to file ${writeTo}`)
  } )
  // re-require langauge file
  delete require.cache[require.resolve(`./${writeTo.split('.')[0]}`)]
  const newContent = require(`./${writeTo.split('.')[0]}`)
  let merged = {...oldFile.translation, ...newContent.translation}
  beginingOfFile = `module.exports =  {
  translation: `
  endOfFile = `};`
  fs.writeFileSync(writeTo, beginingOfFile + JSON.stringify(merged, undefined, '\t') + endOfFile, (err) => {
    console.log(err, `Couldn't write to file ${writeTo}`)
  } )
});
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 8px;
  @media only screen and (max-width: 991px) {
    padding: 0 40px;
  }

  @media only screen and (max-width: 767px) {
    padding: 0 40px 52px;
  }

  @media only screen and (max-width: 480px) {
    padding: 0 20px 52px;
  }
`;

export const Description = styled.div`
  font-size: 17px;
  line-height: 1.4;
  margin-top: 100px;
  margin-bottom: 32px;
  max-width: 390px;
`;
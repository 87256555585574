import React, { useContext, useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { LayoutContext } from '../../providers/LayoutProvider';
import { GlobalContext } from '../../providers/GlobalProvider';
import { PopeyeContext } from '../../providers/PopeyeProvider';
import { AuthContext } from '../../providers/AuthProvider';

import PopeyeTitle from '../../elements/PopeyeTitle/PopeyeTitle';
import Input from '../../elements/Fields/Input';
import Checkbox from '../../elements/Fields/Checkbox';
import SubmitButton from '../../elements/SubmitButton/SubmitButton';
import Fade from '../../elements/Transition/Fade';

import { signUpFormSchema } from '../../constants/schemas';
import { VESSEL_PAGE, REGISTER_PAGE, CONGRATS_PAGE, WAITING_PAGE } from '../../constants/routes';
import { sanitizeTranslation } from '../../utils/sanitizeTranslation';
import { getUrl } from '../../utils/urlHelper';

import { Wrapper, Form, Error } from './SignUp.style';

const SignUp = ({history, location, match}) => {
  const search = getUrl(location);

  const isInvited = Boolean(search.token && search.email);
  const isFromPAP = Boolean(search.origin && search.origin === 'PAP');
  const defaultValues = {
    email: isInvited || isFromPAP ? search.email : '',
    firstName: isFromPAP ? search.first_name : '',
    lastName: isFromPAP ? search.last_name : '',
    agreement: Boolean(isFromPAP)
  }

  const { t } = useTranslation();
  const { termsOfServiceLink, privacyPolicyLink, marinas } = useContext(GlobalContext);
  const { addBoater } = useContext(PopeyeContext);
  const { signUp } = useContext(AuthContext);
  const [{ loading }] = useContext(LayoutContext);

  const [ error, setError ] = useState(null);

  const {
    email,
    firstName,
    lastName,
    password,
    agreement
  } = signUpFormSchema;

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({ defaultValues });

  const onSubmit = async (formProps) => {
    setError(null);
    if(loading) return;

    const { firstName, lastName, email, password } = formProps;

    let data = {
      first_name: firstName,
      last_name: lastName,
      email,
      password
    };

    // Signup boater and continue if the boater is already exists
    try {
      const resSignUp = await signUp(data);
      if(resSignUp && resSignUp.uid) {
        data = { ...data, firebase_id: resSignUp.uid }
      }
    } catch (e) {
      console.error(e)
    }

    if(isInvited) {
      data = { ...data, token: search.token }
    }

    const res = await addBoater(data);

    if(res.error_type && res.error_type === 'CONFLICT') {
      setError(t('BOATER_SIGNED_UP_ERROR'));
    } else {
      /**
       * Fill vessel details -> if there's a need to fill vessel details. 
       * true => need to fill.
       */
      const { fill_vessel_details, popeye_loyalty_status } = res;
      /**
       * Invited:
       *  1) if no need to fill vessel details => CONGRATS PAGE (INSTEAD PLEDGES)
       *  2) if need to fill => to the vessel addition page.
       * 
       * Pending signature:
       * To the congrats page.
       * 
       * Waiting List:
       * 1) if need to the fill vessel details => the vessel addition page.
       * 2) Otherwise to the waiting list page
       * 
       * Active:
       *  1) Shows error
       * 
       */

      if (popeye_loyalty_status === 'ON_WAITING_LIST' && !fill_vessel_details) {
        if(isFromPAP) {
          history.push(`/${match.params.locale}${WAITING_PAGE}`);
        } else {
          history.push(`/${match.params.locale}${REGISTER_PAGE}`);
        }
      } else if (popeye_loyalty_status === 'PENDING_SIGNATURE' || (popeye_loyalty_status === 'INVITED' && !fill_vessel_details)) {
        history.push(`/${match.params.locale}${CONGRATS_PAGE}`);
      } else {
        if(isInvited || isFromPAP) {
          history.push(`/${match.params.locale}${VESSEL_PAGE}`);
        } else {
          history.push(`/${match.params.locale}${REGISTER_PAGE}`);
        }
      }
    }
  };

  useEffect(() => {
    const init = async () => {
      const { first_name: firstName, last_name: lastName, email } = search;
      await onSubmit({ firstName, lastName, email });
    }
    if(isFromPAP && marinas && marinas.length) {
      setTimeout(async () => {
        init();
      }, 200);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ isFromPAP, marinas ]);

  return (
    <Fade>
      <Wrapper>
        {!isFromPAP && (
          <Fragment>
            <PopeyeTitle />
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Input
                disabled={isInvited}
                placeholder={email.placeholder}
                errors={errors.email}
                {...register('email', email.options)}
              />
              <Input
                placeholder={firstName.placeholder}
                errors={errors.firstName}
                {...register('firstName', firstName.options)}
              />
              <Input
                placeholder={lastName.placeholder}
                errors={errors.lastName}
                {...register('lastName', lastName.options)}
              />
              <Input
                placeholder={password.placeholder}
                errors={errors.password}
                type="password"
                {...register('password', password.options)}
              />
              <Checkbox
                label={sanitizeTranslation(t('SIGN_UP_TERMS_LABEL', { terms_of_service_link: termsOfServiceLink, privacy_policy_link: privacyPolicyLink}))}
                errors={errors.agreement}
                {...register('agreement', agreement.options)}
              />
              <SubmitButton
                type="submit"
                label={t('POPEYE_REGISTRATION_FIRST_STEP_CTA')}
                isLoading={loading}
              />
              {Boolean(error) && <Error>{error}</Error>}
            </Form>
          </Fragment>
        )}
      </Wrapper>
    </Fade>
  )
}

export default withRouter(SignUp);
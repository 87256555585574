module.exports =  {
  translation: {
	"POPEYE_REGISTRATION_THIRD_STEP_COMPLETE_TITLE": "Herzliche Glückwünsche!<br>Du bist dem Club beigetreten",
	"POPEYE_REGISTRATION_THIRD_STEP_COMPLETE_BODY": "Wir laden Sie ein, Pick-a-Pier zu erkunden,<br>finden Sie eine Marina an Ihrem nächsten Zielort sammeln Sie erste Punkte.",
	"POPEYE_REGISTRATION_THIRD_STEP_COMPLETE_CTA": "Weiter zu Pier a Pier",
	"INSERT_CODE": "Ich habe einen Code",
	"INSERT_CODE_ERROR": "Wir konnten diesen Code nicht zuordnen. Bitte versuchen Sie es erneut.",
	"POPEYE_FOOTER": "@ {{current_year}} King Features Syndicate",
	"POPEYE_HOMEPAGE_TITLE": "Wir präsentieren das fortschrittlichste, attraktivste und nachhaltigste Treueprogramm für Bootsfahrer",
	"POPEYE_HOMEPAGE_CTA": "Werden Sie Mitglied im Club",
	"POPEYE_HOMEPAGE_VALUE1": "Sie erhalten vorrangigen Zugang zu den besten Marinas",
	"POPEYE_HOMEPAGE_VALUE2": "Sammeln Sie Punkte und lösen Sie Prämien ein",
	"POPEYE_HOMEPAGE_VALUE3": "Fördern Sie nachhaltigere Gewässer",
	"POPEYE_HOMEPAGE_DESC": "Der Popeye® Sail Club ist ein exklusives Treueprogramm, das für zukunftsorientierte Bootsfahrer entwickelt wurde. Diese einzigartige Mitgliedschaft profitiert von der Technologie von Pick a Pier und seinen erstklassigen Partnern und bietet Ihnen ein einzigartiges Segelerlebnis.",
	"POPEYE_HOMEPAGE_RULES_TITLE": "Begrüßen Sie das, worauf Sie gewartet haben.",
	"POPEYE_HOMEPAGE_RULE1_TITLE": "Lassen Sie sich für jede Fahrt belohnen.",
	"POPEYE_HOMEPAGE_RULE1_DESC": "Für jede Transaktion erhalten Sie Punkte, die Sie für Reservierungen und andere Services der Marina einlösen können. Je früher Sie buchen, desto mehr profitieren Sie.",
	"POPEYE_HOMEPAGE_RULE2_TITLE": "Sichern Sie sich monatlich Bargeld für Reservierungen.",
	"POPEYE_HOMEPAGE_RULE2_DESC": "Sie erhalten eine monatliche Gutschrift von 50 €, die Sie für jede Marina-Reservierung über Pick a Pier verwenden können.",
	"POPEYE_HOMEPAGE_RULE3_TITLE": "Mit dem Captain-Status profitieren Sie noch mehr.",
	"POPEYE_HOMEPAGE_RULE3_DESC": "Der Captain-Status ist für die passioniertesten Bootsfahrer reserviert und ermöglicht kostenlose Dienstleistungen und Sonderangebote in Marinas.",
	"POPEYE_HOMEPAGE_SUSTAINABILITY_TITLE": "Das Versprechen für nachhaltiges Bootsfahren",
	"POPEYE_HOMEPAGE_SUSTAINABILITY_BODY": "In Zusammenarbeit mit der Blue Flag bitten wir alle unsere neuen Bootsfahrer, das Versprechen für nachhaltiges Bootsfahren (Sustainable Boating Pledge) abzulegen und sich an umweltfreundliche Praktiken auf See und an Land zu halten.",
	"POPEYE_HOMEPAGE_BOTTOM": "Der Popeye® Sail Club ist derzeit nur auf Einladung zugänglich.",
	"FIRST_NAME_REQUIRED": "Vorname ist erforderlich!",
	"FIRST_NAME_INCORRECT": "Der Vorname ist falsch!",
	"FIRST_NAME_LENGTH": "Der Vorname muss länger als 2 Zeichen sein!",
	"LAST_NAME_REQUIRED": "Nachname ist erforderlich!",
	"LAST_NAME_INCORRECT": "Nachname ist falsch!",
	"LAST_NAME_LENGTH": "Der Nachname muss länger als 2 Zeichen sein!",
	"EMAIL_REQUIRED": "Bitte geben Sie eine gültige E-Mail-Adresse an",
	"INVALID_EMAIL": "Diese E-Mail ist ungültig. Bitte versuchen Sie es erneut.",
	"EMAIL_PLACEHOLDER": "E-Mail",
	"FIRST_NAME": "Vorname",
	"LAST_NAME": "Nachname",
	"SIGN_UP_TERMS_LABEL": "Indem Sie dieses Kästchen ankreuzen, bestätigen Sie, dass Sie unsere <a href={{terms_of_service_link}} target=_blank title=Dienstleistungsbedingungen>Dienstleistungsbedingungen</a> und <a href={{privacy_policy_link}} target=_blank title=Pick a Pier Datenschutzrichtlinie>Datenschutzrichtlinie</a> gelesen und akzeptiert haben.",
	"SIGN_UP_TERMS_REQUIRED": "Die Zustimmung zu den Allgemeinen Geschäftsbedingungen ist erforderlich",
	"POPEYE_REGISTRATION_FIRST_STEP_CTA": "WEITER",
	"POPEYE_REGISTRATION_SECOND_STEP_OWNER": "Sind Sie ein Bootseigner?",
	"POPEYE_REGISTRATION_SECOND_STEP_CTA": "WEITER",
	"BOAT_OWNER_YES": "Ja",
	"BOAT_OWNER_NO": "Nein",
	"VESSEL_NAME_LABEL": "Name des Bootes",
	"VESSEL_NAME_REQUIRED": "Bootsname ist erforderlich",
	"VESSEL_NAME_MIN_LETTERS": "Muss mindestens 3 Buchstaben enthalten",
	"LOA_PLACEHOLDER": "L.O.A",
	"VESSEL_LOA_REQUIRED": "L.O.A. ist erforderlich",
	"VESSEL_LENGTH_ERROR": "Die Länge sollte 0,1 oder mehr betragen",
	"VESSEL_MAX_LENGTH_ERROR": "Die Länge sollte weniger als 1000 betragen",
	"BEAM_PLACEHOLDER": "Breite",
	"VESSEL_BEAM_REQUIRED": "Bootsbreite ist erforderlich",
	"VESSEL_BEAM_ERROR": "Die Breite sollte 0,1 oder mehr betragen",
	"VESSEL_MAX_BEAM_ERROR": "Die Breite sollte weniger als 1000 betragen",
	"DRAFT_PLACEHOLDER": "Tiefgang",
	"VESSEL_DRAFT_REQUIRED": "Tiefgang ist erforderlich",
	"VESSEL_DRAFT_ERROR": "Der Tiefgang sollte 0,1 oder mehr sein.",
	"VESSEL_MAX_DRAFT_ERROR": "Der Tiefgang sollte weniger als 1000 betragen",
	"HOME_MARINA_LABEL": "Heimatmarina auswählen",
	"HOME_MARINA_REQUIRED": "Heimatmarina ist erforderlich",
	"NO_RESULTS_FOUND_SEARCH_AGAIN_OR_SELECT": "Keine Ergebnisse gefunden. Vergewissern Sie sich, dass Sie keine Tippfehler haben, oder klicken Sie",
	"OTHER": "Andere",
	"ADD": "Hinzufügen",
	"HOME_MARINA_NAME_LABEL": "Heimatmarina",
	"POPEYE_REGISTRATION_WAITLIST_TITLE": "Vielen Dank, dass Sie sich in die Warteliste eingetragen haben.",
	"POPEYE_REGISTRATION_WAITLIST_BODY": "Die Mitgliedschaft im Popeye Sails Club ist im Moment nur auf Einladung möglich. Wir werden in Kürze für alle Bootsfahrer zugängig sein, und Sie erfahren es als einer der Ersten.",
	"SIGNATURE_TITLE": "Unterschreiben Sie hier",
	"SIGNATURE_CLEAR": "Löschen",
	"POPEYE_REGISTRATION_THIRD_STEP_BACK": "Zurück",
	"SIGNATURE_CTA": "Speichern",
	"BOATER_SIGNED_UP_ERROR": "Die E-Mail-Adresse wird bereits von einem anderen Konto verwendet.",
	"POPEYE_REGISTRATION_INSERT_CODE_PLACE_HOLDER": "Haben Sie einen Registrierungscode?",
	"POPEYE_REGISTRATION_CODE_PRIMARY_CTA": "Mit einem Code registrieren",
	"PASSWORD_PLACEHOLDER": "Passwort",
	"PASSWORD_LENGTH": "Das Passwort muss länger als 6 Zeichen sein",
	"PASSWORD_TOO_LONG": "Ihr Passwort sollte maximal 20 Zeichen enthalten",
	"PASSWORD_REQUIRED": "Bitte wählen Sie ein Passwort",
	"POPEYE_HOMEPAGE_RULE4_TITLE": "Lösen Sie Punkte für Rabatte ein.",
	"POPEYE_HOMEPAGE_RULE4_DESC": "Lösen Sie Ihre gesammelten Punkte bei Ihrer nächsten Reservierung in teilnehmenden Pick-a-Pier-Marinas ein.",
	"POPEYE_REGISTRATION_CODE_EXPLANATION": "Um Mitglied zu werden, benötigen Sie eine Einladung oder einen gültigen Registrierungscode",
	"POPEYE_REGISTRATION_CODE_SECONDARY_CTA": "Weiter ohne Code",
	"FOR_BOATERS_MENU_ITEM": "Für BOOTSFAHRER",
	"FOR_MARINAS_MENU_ITEM": "Für MARINAS",
	"FOR_THE_OCEAN_MENU_ITEM": "FÜR DAS MEER",
	"LOG_IN_MENU_ITEM": "ANMELDEN",
	"SIGN_UP_MENU_ITEM": "REGISTRIEREN",
	"POPEYE_HOMEPAGE_INSPIRE": "Werden Sie Teil einer Community von Menschen, die sich mit vereinten Kräften dafür einsetzen, den Bootssport nachhaltiger und lohnender zu machen.",
	"PAP_LINK_TEXT": "Neu bei Pick a Pier? <br>Erfahren Sie mehr darüber, warum Bootsfahrer uns lieben →",
	"NAVBAR_POPEYE_LABEL": "Popeye® Sail Club",
	"FOOTER_POPEYE": "Popeye® Sail Club",
	"FOOTER_COMPANY": "Unternehmen",
	"FOOTER_ABOUT": "Über uns",
	"FOOTER_TEAM": "Team",
	"FOOTER_CAREER": "Karriere",
	"FOOTER_SOLUTIONS": "Lösungen",
	"FOOTER_MARINAS": "Für Marinas",
	"FOOTER_BOATERS": "Für Bootsfahrer",
	"FOOTER_OCEAN": "Für das Meer",
	"FOOTER_RESOURCES": "Ressourcen",
	"FOOTER_BLOG": "Blog",
	"FOOTER_PRESS": "Presseraum",
	"FOOTER_CONTACT": "Kontakt",
	"FOOTER_TERMS": "Nutzungsbedingungen",
	"FOOTER_PRIVACY": "Datenschutzerklärung",
	"FOOTER_GDPR": "DSGVO-Konformität",
	"FOOTER_SUBSCRIBE_DESC": "Abonnieren Sie unseren Newsletter",
	"FOOTER_SUBSCRIBE_PLACEHOLDER": "E-Mail",
	"FOOTER_SUBSCRIBE_SUCCESS": "Vielen Dank, dass Sie sich angemeldet haben!",
	"FOOTER_FOLLOW": "Folgen Sie uns",
	"FOOTER_CTA": "Abonnieren"
}};
import React, { useContext, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { useForm, Controller } from 'react-hook-form';
import { themeGet } from '@styled-system/theme-get';
import { withTheme } from 'styled-components'

import { PopeyeContext } from '../../providers/PopeyeProvider';
import { LayoutContext } from '../../providers/LayoutProvider';

import SubmitButton from '../../elements/SubmitButton/SubmitButton';
import BackButton from '../../elements/BackButton/BackButton';
import Fade from '../../elements/Transition/Fade';
import PopeyeTitle from '../../elements/PopeyeTitle/PopeyeTitle';
import Input from '../../elements/Fields/Input';

import { registerFormSchema } from '../../constants/schemas';
import { config } from '../../settings/config';


import { VESSEL_PAGE, REGISTER_PAGE, WAITING_PAGE, CONGRATS_PAGE } from '../../constants/routes';

import { Wrapper, Form } from './ReferralCode.style';

const ReferralCode = (props) => {
  const { match, history } = props;
  const { t } = useTranslation();
  const { addBoater, firstName, lastName, email } = useContext(PopeyeContext);
  const [{ loading }] = useContext(LayoutContext);

  const [ disabled, setDisabled ] = useState(true);

  const { invitationCode } = registerFormSchema;

  const {
    handleSubmit,
    formState: { errors },
    control,
    setError: setFormError
  } = useForm();

  const onSubmit = async (formProps) => {
    if(loading) return;

    const { invitationCode: invitationCodeBeforeFormatting } = formProps;

    let data = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      popeye_invitation_code: invitationCodeBeforeFormatting ? invitationCodeBeforeFormatting.toLowerCase() : null
    };

    const res = await addBoater(data);

    if (res.error_code && config.POPEYE_INVITATION_CODE_ERRORS.includes(res.error_code)) {
      setFormError('invitationCode', { message: t('INSERT_CODE_ERROR') })
    } else {
      const { fill_vessel_details, popeye_loyalty_status } = res;

      if (popeye_loyalty_status === 'ON_WAITING_LIST' && !fill_vessel_details) {
        history.push(`/${match.params.locale}${WAITING_PAGE}`);
      } else if (popeye_loyalty_status === 'PENDING_SIGNATURE' || (popeye_loyalty_status === 'INVITED' && !fill_vessel_details)) {
        history.push(`/${match.params.locale}${CONGRATS_PAGE}`);
      } else {
        history.push(`/${match.params.locale}${VESSEL_PAGE}`);
      }
    }
  };

  return (
    <Fade>
      <Wrapper>
        <PopeyeTitle />
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="invitationCode"
            control={control}
            rules={invitationCode.options}
            render={({ field }) => {
              console.log(field);
              const { onChange, ...rest } = field;
              return (
                <Input
                  placeholder={invitationCode.placeholder}
                  className='invitationCode'
                  errors={errors.invitationCode}
                  fieldStyles={{ marginBottom: 24 }}
                  style={{ border: `4px solid ${themeGet('border.3', '#00ae42')(props)}`, textTransform: 'lowercase' }}
                  onChange={ e => {
                    setDisabled(!e.target.value);
                    onChange(e);
                  } }
                  {...rest}
                />
              )
            }}
          />
          <SubmitButton
            disabled={ disabled }
            type="submit"
            label={t('POPEYE_REGISTRATION_SECOND_STEP_CTA')}
            isLoading={loading}
          />
        </Form>
        {!isMobile && <BackButton label={t('POPEYE_REGISTRATION_THIRD_STEP_BACK')} onClick={() => history.replace(`/${match.params.locale}${REGISTER_PAGE}`)}/>}
      </Wrapper>
    </Fade>
  )
}

export default withRouter(withTheme(ReferralCode));
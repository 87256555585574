import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import backgroundImg from '../../../images/note.png';
import backgroundMobileImg from '../../../images/note_mobile.png';

const backgroundStyles = css`
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

const flexStyles = css`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const Section = styled.section`
  width: 100%;
  box-sizing: border-box;
  ${flexStyles};
  ${backgroundStyles};
  background-image: url(${backgroundImg});
  padding: 287px 0;
  @media only screen and (max-width: 767px) {
    padding: 176px 0 287px;
  }
  @media only screen and (max-width: 480px) {
    padding: 176px 0 237px;
    background-image: url(${backgroundMobileImg});
  }
`;

export const Container = styled.div`
  width: 100%;
  box-sizing: border-box;
  max-width: 1056px;
  padding: 0 34px 0;
  ${flexStyles};
  @media only screen and (max-width: 767px) {
    padding: 0 40px 0;
  }
  @media only screen and (max-width: 480px) {
    padding: 0 20px 0;
  }
`;

export const Description = styled.p`
  max-width: 742px;
  font-size: 33px;
  line-height: 40px;
  font-weight: 700;
  color: ${themeGet('text.1', '#ffffff')};
  text-align: center;
  @media only screen and (max-width: 480px) {
    max-width: 296px;
  }
`;

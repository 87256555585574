import { post } from '../utils/api';

const popeyeService = {
  addBoater: async function (data) {
    return await post('/popeye/add/boater', data);
  },
  addVessel: async function (data) {
    return await post('/popeye/add/vessel', data);
  }
  // activate: async function (data) {
  //   return await post('/popeye/activate', data);
  // }
};

export default popeyeService;

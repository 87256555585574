import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

import { createGlobalStyle } from 'styled-components';

const AppStyle = createGlobalStyle`
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: normal;
  }

  @keyframes load {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  body {
    margin: 0;
    font-family: 'Lato', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: ${themeGet('color.6', '#ffffff')};
    color: ${themeGet('text.7', '#121A2C')};
    text-align: center;
    @media only screen and (max-width: 767px) {
      background-color: ${themeGet('primary.0', '#01257a')};
      color: ${themeGet('text.1', '#ffffff')};
    }
  }

  h1, h2, h3, p {
    margin: 0;
  }

  a {
    color: ${themeGet('text.8', '#0036b1')};
    text-decoration: none;
    @media only screen and (max-width: 767px) {
      color: ${themeGet('text.5', '#67b5ff')};
    }
  }

  ul {
    margin: 0;
    list-style: none;
    padding: 0;
  }

  button {
    outline: none;
    border: none;
    cursor: pointer;
  }
`;

export const AppWrapper = styled.div`
  min-height: 100vh;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  // &.home-page-wrapper {
  //   background-color: ${themeGet('color.6', '#ffffff')};
  // }

  @media only screen and (max-width: 991px) {

  }

  @media only screen and (max-width: 767px) {
    &.home-page-wrapper {
      background-color: ${themeGet('primary.0', '#01257a')};
    }
  }

  @media only screen and (max-width: 480px) {

  }
`;

export default AppStyle;

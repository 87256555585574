module.exports =  {
  translation: {
	"POPEYE_HOMEPAGE_TITLE": "Introducing the most advanced, rewarding and sustainable boating club.",
	"POPEYE_HOMEPAGE_CTA": "Join now",
	"POPEYE_HOMEPAGE_VALUE1": "Get priority access to the best marinas",
	"POPEYE_HOMEPAGE_VALUE2": "Earn points and redeem rewards",
	"POPEYE_HOMEPAGE_VALUE3": "Promote a more sustainable ocean",
	"POPEYE_HOMEPAGE_DESC": "Popeye® Sail Club is the exclusive loyalty program created for the most forward-thinking boaters. Powered by Pick a Pier’s technology and world-class partners, this first-of-its-kind membership unlocks a uniquely rewarding sailing experience.",
	"POPEYE_HOMEPAGE_RULES_TITLE": "Say Ahoy to everything you’ve been waiting for.",
	"POPEYE_HOMEPAGE_RULE1_TITLE": "Discover the best marinas.",
	"POPEYE_HOMEPAGE_RULE1_DESC": "Browse some of the best destinations across Europe, with more marinas joining every day.",
	"POPEYE_HOMEPAGE_RULE2_TITLE": "Get rewarded for every sail.",
	"POPEYE_HOMEPAGE_RULE2_DESC": "Booking a berth at a destination marina or letting us know you plan to sail away earns you points.",
	"POPEYE_HOMEPAGE_RULE3_TITLE": "Plan early and earn even more.",
	"POPEYE_HOMEPAGE_RULE3_DESC": "Get up to 3x the points just by booking ahead, while maintaining maximum flexibility.",
	"POPEYE_HOMEPAGE_RULE4_TITLE": "Redeem points for discounts.",
	"POPEYE_HOMEPAGE_RULE4_DESC": "Apply your earned points towards your next reservation at participating Pick a Pier marinas.",
	"POPEYE_HOMEPAGE_SUSTAINABILITY_TITLE": "A big win for you. <br>A huge win for the ocean.",
	"POPEYE_HOMEPAGE_SUSTAINABILITY_BODY": "In partnership with Blue Flag, we ask all our new boaters to take the Sustainable Boating Pledge and adhere to eco-friendly practices at sea and on-shore.",
	"POPEYE_HOMEPAGE_BOTTOM": "Currently accepting select boaters by invitation-only",
	"FIRST_NAME_REQUIRED": "First Name is Required!",
	"FIRST_NAME_INCORRECT": "First Name is incorrect!",
	"FIRST_NAME_LENGTH": "First Name has to be longer than 2 characters!",
	"LAST_NAME_REQUIRED": "Last Name is Required!",
	"LAST_NAME_INCORRECT": "Last Name is incorrect!",
	"LAST_NAME_LENGTH": "Last Name has to be longer than 2 characters!",
	"EMAIL_REQUIRED": "Please provide a valid email address",
	"INVALID_EMAIL": "This email is invalid. Try again.",
	"EMAIL_PLACEHOLDER": "Email",
	"FIRST_NAME": "First Name",
	"LAST_NAME": "Last Name",
	"SIGN_UP_TERMS_LABEL": "By checking this box you confirm that you have read and agreed to our <a href={{terms_of_service_link}} target=_blank title=Terms of Service>Terms of Service</a> and <a href={{privacy_policy_link}} target=_blank title=Pick a pier Privacy Policy>Privacy Policy</a>",
	"SIGN_UP_TERMS_REQUIRED": "Agreeing to Terms & Conditions is required",
	"POPEYE_REGISTRATION_FIRST_STEP_CTA": "NEXT",
	"POPEYE_REGISTRATION_SECOND_STEP_OWNER": "Are you a boat owner?",
	"POPEYE_REGISTRATION_SECOND_STEP_CTA": "NEXT",
	"BOAT_OWNER_YES": "Yes",
	"BOAT_OWNER_NO": "No",
	"VESSEL_NAME_LABEL": "Boat Name",
	"VESSEL_NAME_REQUIRED": "Boat name is required",
	"VESSEL_NAME_MIN_LETTERS": "Must include at least 3 letters",
	"LOA_PLACEHOLDER": "L.O.A",
	"VESSEL_LOA_REQUIRED": "L.O.A is required",
	"VESSEL_LENGTH_ERROR": "Length should be equal to 0.1 or more",
	"VESSEL_MAX_LENGTH_ERROR": "Length should be less than 1000",
	"BEAM_PLACEHOLDER": "Beam",
	"VESSEL_BEAM_REQUIRED": "Beam is required",
	"VESSEL_BEAM_ERROR": "Beam should be equal to 0.1 or more",
	"VESSEL_MAX_BEAM_ERROR": "Beam should be less than 1000",
	"DRAFT_PLACEHOLDER": "Draft",
	"VESSEL_DRAFT_REQUIRED": "Draft is required",
	"VESSEL_DRAFT_ERROR": "Draft should be equal to 0.1 or more",
	"VESSEL_MAX_DRAFT_ERROR": "Draft should be less than 1000",
	"HOME_MARINA_LABEL": "Select Home Marina",
	"HOME_MARINA_REQUIRED": "Home Marina is required",
	"NO_RESULTS_FOUND_SEARCH_AGAIN_OR_SELECT": "No results found. Make sure you have no typos, or click",
	"OTHER": "Other",
	"ADD": "Add",
	"HOME_MARINA_NAME_LABEL": "Home Marina",
	"POPEYE_REGISTRATION_WAITLIST_TITLE": "Thank you for signing up for the waiting list.",
	"POPEYE_REGISTRATION_WAITLIST_BODY": "At this time, the Popeye Sails Club is by invitation only. We will soon be available to all boaters, and you’ll be among the first to know when we do.",
	"POPEYE_REGISTRATION_THIRD_STEP_COMPLETE_TITLE": "Congratulations!<br>You've joined the club",
	"POPEYE_REGISTRATION_THIRD_STEP_COMPLETE_BODY": "We invite you to explore Pick a Pier,<br>find a marina in your next destination, and start collecting points.",
	"POPEYE_REGISTRATION_THIRD_STEP_COMPLETE_CTA": "Go to Pick a Pier",
	"SIGNATURE_TITLE": "Draw your Signature",
	"SIGNATURE_CLEAR": "Clear",
	"POPEYE_REGISTRATION_THIRD_STEP_BACK": "Back",
	"SIGNATURE_CTA": "Save",
	"BOATER_SIGNED_UP_ERROR": "The email address is already in use by another account.",
	"POPEYE_REGISTRATION_INSERT_CODE_PLACE_HOLDER": "Enter your registration code",
	"INSERT_CODE_ERROR": "We could not match that code, please try again",
	"POPEYE_FOOTER": "@ {{current_year}} King Features Syndicate",
	"INSERT_CODE": "I have a code",
	"POPEYE_REGISTRATION_CODE_EXPLANATION": "To become a member, you need an invite or an active registration code",
	"POPEYE_REGISTRATION_CODE_PRIMARY_CTA": "Register with a code",
	"POPEYE_REGISTRATION_CODE_SECONDARY_CTA": "Continue without a code",
	"PASSWORD_PLACEHOLDER": "Password",
	"PASSWORD_REQUIRED": "Please choose a password",
	"PASSWORD_LENGTH": "Password has to be longer than 6 characters",
	"PASSWORD_TOO_LONG": "Your password should contain 20 characters max",
	"FOR_BOATERS_MENU_ITEM": "For Boaters",
	"FOR_MARINAS_MENU_ITEM": "For Marinas",
	"FOR_THE_OCEAN_MENU_ITEM": "For the Ocean",
	"LOG_IN_MENU_ITEM": "Log In",
	"SIGN_UP_MENU_ITEM": "Sign Up",
	"POPEYE_HOMEPAGE_INSPIRE": "Become part of a community of those who put their collective power into making boating more sustainable and rewarding.",
	"PAP_LINK_TEXT": "New to Pick a Pier? <br>Learn more about why boaters love us →",
	"FOOTER_COMPANY": "Company",
	"FOOTER_ABOUT": "About Us",
	"FOOTER_TEAM": "Team",
	"FOOTER_CAREER": "Career",
	"FOOTER_SOLUTIONS": "Solutions",
	"FOOTER_POPEYE": "Popeye® Sail Club",
	"FOOTER_MARINAS": "For Marinas",
	"FOOTER_BOATERS": "For Boaters",
	"FOOTER_OCEAN": "For the Ocean",
	"FOOTER_RESOURCES": "Resources",
	"FOOTER_BLOG": "Blog",
	"FOOTER_PRESS": "Press Room",
	"FOOTER_CONTACT": "Contact Us",
	"FOOTER_TERMS": "Terms of use",
	"FOOTER_PRIVACY": "Privacy policy",
	"FOOTER_GDPR": "GDPR compliance",
	"FOOTER_SUBSCRIBE_DESC": "Subscribe to our newsletter",
	"FOOTER_SUBSCRIBE_PLACEHOLDER": "Email",
	"FOOTER_SUBSCRIBE_SUCCESS": "Thank you for subscribing!",
	"FOOTER_FOLLOW": "Follow us",
	"FOOTER_CTA": "Subscribe",
	"NAVBAR_POPEYE_LABEL": "Popeye® Sail Club"
}};
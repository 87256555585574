import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const Wrapper = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 412px;
  box-sizing: border-box;
  padding-bottom: 158px;

  @media only screen and (max-width: 767px) {
    padding-bottom: 40px;
  }

  @media only screen and (max-width: 480px) {
    max-width: 100%;
    padding: 0 20px 40px;
  }
`;

export const Form = styled.form`
  padding-top: 62px;
  width: 100%;
  position: relative;
  .invitationCode::placeholder {
    text-align: center;
  }
  @media only screen and (max-width: 767px) {
    padding-top: 92px;
  }
  @media only screen and (max-width: 480px) {
    .checkbox-field {
      margin-bottom: 56px
    }
  }
`;

export const Error = styled.span`
  position: absolute;
  top: 10px;
  font-weight: bold;
  color: ${themeGet('error.0', '#F9503D')};
  display: block;
  padding: 2px 27px;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
`
import React from 'react';
import { withRouter } from 'react-router-dom';
import { CopyrightText } from './Copyright.style';
import { useTranslation } from 'react-i18next';


const Copyright = ({ match, location }) => {
  const { t } = useTranslation();
  const isHomePage = match.url === location.pathname;
  const current_year = new Date().getFullYear()
  return !isHomePage && <CopyrightText>{t('POPEYE_FOOTER', {current_year})}</CopyrightText>
}

export default withRouter(Copyright);

import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const Wrapper = styled.div`

`;

export const Menu = styled.ul`
  display: flex;
  align-items: center;
`;

export const Item = styled.li`
  display: flex;
  align-items: center;
  a {
    font-size: 14px;
    line-height: 17px;
    font-weight: 600;
    color: ${themeGet('text.13', '#828282')};
    &:hover {
      opacity: 0.8;
    }
  }
`;


export const Divider = styled.div`
  background-color: ${themeGet('text.13', '#828282')};
  height: 14px;
  width: 1px;
  margin: 0 3px;
  @media only screen and (max-width: 991px) {
    ${({ first }) => first && css`display: none;` }
  }
`;


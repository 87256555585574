import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter, Switch, Route } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { localeOptions } from './settings/locales';
import history from './settings/history';

const langsRegxp = localeOptions.join('|');

const BasicComponent = () => {
  return (
    // <React.StrictMode>
    <HashRouter>
      <Switch>
        <Route path={`/:locale(${langsRegxp})`} component={App} />
        <Route path='/' component={App} />
      </Switch>
    </HashRouter>
    // </React.StrictMode>
  );
};

ReactDOM.render(
  <BasicComponent />,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Wrapper, Title, Row, Button, Circle, Label } from './Switch.style';
import isNull from 'lodash/isNull'
 
const Switch = ({ className='', title, labelOn, labelOff, value, onChange=() => {}, error, constantValue }) => {
  const { t } = useTranslation();

  return (
    <Wrapper className={`switch-field ${className}`}>
      {Boolean(title) && <Title>{t(title)}</Title>}
      <Row>
        <Button className={constantValue && 'disabled-button'} disabled={constantValue} onClick={() => onChange(true)}>
          <Circle className={value ? 'active' : ''} />
          <Label>{t(labelOn)}</Label>
        </Button>
        <Button className={constantValue && 'disabled-button'} disabled={constantValue} onClick={() => onChange(false)}>
          <Circle className={isNull(value) ? '' : value ? '' : 'active'} />
          <Label>{t(labelOff)}</Label>
        </Button>
      </Row>
    </Wrapper>
  )
}

export default Switch;
module.exports =  {
  translation: {
	"POPEYE_HOMEPAGE_TITLE": "Ecco il programma fedeltà per barcaioli più avanzato, gratificante e sostenibile",
	"POPEYE_HOMEPAGE_CTA": "Unisciti al Club",
	"POPEYE_HOMEPAGE_VALUE1": "Ottieni accesso prioritario ai migliori porti turistici",
	"POPEYE_HOMEPAGE_VALUE2": "Guadagna punti e riscatta ricompense",
	"POPEYE_HOMEPAGE_VALUE3": "Favorisci un oceano più sostenibile",
	"POPEYE_HOMEPAGE_DESC": "Popeye® Sail Club è il programma fedeltà esclusivo creato per barcaioli innovativi. Supportato dalla tecnologia di Pick a Pier e da partner di classe mondiale, questo abbonamento unico permette di ottenere un'esperienza di navigazione davvero gratificante.",
	"POPEYE_HOMEPAGE_RULES_TITLE": "Di' Ahoy a tutto ciò che stavi aspettando.",
	"POPEYE_HOMEPAGE_RULE1_TITLE": "Ricevi ricompense per ogni viaggio in barca.",
	"POPEYE_HOMEPAGE_RULE1_DESC": "Ogni transazione permette di guadagnare punti che possono essere riscattati per prenotazioni e altri servizi dei porti turistici. Prima prenoti, più guadagni.",
	"POPEYE_HOMEPAGE_RULE2_TITLE": "Ricevi denaro mensilmente per le tue prenotazioni.",
	"POPEYE_HOMEPAGE_RULE2_DESC": "Ricevi un credito di 50 € che puoi usare per prenotazioni in porti turistici tramite Pick a Pier.",
	"POPEYE_HOMEPAGE_RULE3_TITLE": "Ricevi ancora di più con il Captain Status.",
	"POPEYE_HOMEPAGE_RULE3_DESC": "Riservato ai barcaioli più appassionati, il nostro Captain Status sblocca servizi gratis e offerte speciali nei porti turistici.",
	"POPEYE_HOMEPAGE_RULE4_TITLE": "Riscatta punti per sconti.",
	"POPEYE_HOMEPAGE_RULE4_DESC": "Usa i punti guadagnati per la tua prossima prenotazione nei porti turistici che collaborano con Pick a Pier.",
	"POPEYE_HOMEPAGE_SUSTAINABILITY_TITLE": "La promessa di nautica sostenibile",
	"POPEYE_HOMEPAGE_SUSTAINABILITY_BODY": "In collaborazione con Bandiera Blu, chiediamo a tutti i nostri nuovi barcaioli di fare la promessa di nautica sostenibile e aderire alle pratiche ecologiche in mare e a terra.",
	"POPEYE_HOMEPAGE_BOTTOM": "Tieni pronto il tuo codice di registrazione",
	"FIRST_NAME_REQUIRED": "Il nome è obbligatorio!",
	"FIRST_NAME_INCORRECT": "Il nome è errato!",
	"FIRST_NAME_LENGTH": "Il nome deve essere più lungo di 2 caratteri!",
	"LAST_NAME_REQUIRED": "Il cognome è obbligatorio!",
	"LAST_NAME_INCORRECT": "Il cognome è errato!",
	"LAST_NAME_LENGTH": "Il cognome deve essere più lungo di 2 caratteri!",
	"EMAIL_REQUIRED": "Fornisci un indirizzo email valido",
	"INVALID_EMAIL": "Questa email non è valida. Riprova",
	"EMAIL_PLACEHOLDER": "Email",
	"FIRST_NAME": "Nome",
	"LAST_NAME": "Cognome",
	"SIGN_UP_TERMS_LABEL": "Spuntando questa casella confermi di aver letto e accettato i nostri <a href={{terms_of_service_link}} target=_blank title=Terms of Service>termini di servizio</a> e la nostra <a href={{privacy_policy_link}} target=_blank title=Pick a pier Privacy Policy>politica sulla privacy</a>",
	"SIGN_UP_TERMS_REQUIRED": "L'accettazione di termini e condizioni è obbligatoria",
	"POPEYE_REGISTRATION_FIRST_STEP_CTA": "AVANTI",
	"POPEYE_REGISTRATION_SECOND_STEP_OWNER": "Sei il proprietario di una barca?",
	"POPEYE_REGISTRATION_SECOND_STEP_CTA": "AVANTI",
	"BOAT_OWNER_YES": "Sì",
	"BOAT_OWNER_NO": "No",
	"VESSEL_NAME_LABEL": "Nome della barca",
	"VESSEL_NAME_REQUIRED": "Il nome della barca è obbligatorio",
	"VESSEL_NAME_MIN_LETTERS": "Deve includere almeno 3 lettere",
	"LOA_PLACEHOLDER": "Lunghezza fuori tutto",
	"VESSEL_LOA_REQUIRED": "La lunghezza fuori tutto è obbligatoria",
	"VESSEL_LENGTH_ERROR": "La lunghezza deve essere uguale o superiore a 0,1",
	"VESSEL_MAX_LENGTH_ERROR": "La lunghezza deve essere inferiore a 1000",
	"BEAM_PLACEHOLDER": "Larghezza massima",
	"VESSEL_BEAM_REQUIRED": "La larghezza massima è obbligatoria",
	"VESSEL_BEAM_ERROR": "La larghezza massima deve essere uguale o superiore a 0,1",
	"VESSEL_MAX_BEAM_ERROR": "La larghezza massima deve essere inferiore a 1000",
	"DRAFT_PLACEHOLDER": "Pescaggio",
	"VESSEL_DRAFT_REQUIRED": "Il pescaggio è obbligatorio",
	"VESSEL_DRAFT_ERROR": "Il pescaggio deve essere uguale o superiore a 0,1",
	"VESSEL_MAX_DRAFT_ERROR": "Il pescaggio deve essere inferiore a 1000",
	"HOME_MARINA_LABEL": "Seleziona il porto turistico locale",
	"HOME_MARINA_REQUIRED": "Il porto turistico locale è obbligatorio",
	"NO_RESULTS_FOUND_SEARCH_AGAIN_OR_SELECT": "Nessun risultato trovato. Assicurati che non siano presenti errori o clicca",
	"OTHER": "Altro",
	"ADD": "Aggiungi",
	"HOME_MARINA_NAME_LABEL": "Porto turistico locale",
	"POPEYE_REGISTRATION_WAITLIST_TITLE": "Grazie per esserti iscritto alla lista di attesa.",
	"POPEYE_REGISTRATION_WAITLIST_BODY": "In questo momento, il Popeye® Sails Club è solo su invito. Saremo presto disponibili per tutti i barcaioli e sarai tra i primi a saperlo.",
	"POPEYE_REGISTRATION_THIRD_STEP_COMPLETE_TITLE": "Congratulazioni!<br>Ti sei iscritto al club",
	"POPEYE_REGISTRATION_THIRD_STEP_COMPLETE_BODY": "Ti invitiamo a esplorare Pick a Pier,<br>trovare un porto turistico nella tua prossima destinazione e iniziare a raccogliere punti.",
	"POPEYE_REGISTRATION_THIRD_STEP_COMPLETE_CTA": "Vai su Pick a Pier",
	"SIGNATURE_TITLE": "Inserisci la tua firma",
	"SIGNATURE_CLEAR": "Cancella",
	"POPEYE_REGISTRATION_THIRD_STEP_BACK": "Indietro",
	"SIGNATURE_CTA": "Salva",
	"BOATER_SIGNED_UP_ERROR": "L'indirizzo email è già in uso per un altro account.",
	"POPEYE_REGISTRATION_INSERT_CODE_PLACE_HOLDER": "Inserisci il tuo codice di registrazione",
	"INSERT_CODE_ERROR": "Non abbiamo trovato una corrispondenza per il codice, riprova",
	"POPEYE_FOOTER": "@ {{current_year}} King Features Syndicate",
	"INSERT_CODE": "Ho un codice",
	"POPEYE_REGISTRATION_CODE_EXPLANATION": "Al fine di diventare un membro, ti serve un invito o un codice di registrazione attivo",
	"POPEYE_REGISTRATION_CODE_PRIMARY_CTA": "Registrati con un codice",
	"POPEYE_REGISTRATION_CODE_SECONDARY_CTA": "Continua senza un codice",
	"PASSWORD_PLACEHOLDER": "Password",
	"PASSWORD_REQUIRED": "Scegli una password",
	"PASSWORD_LENGTH": "La password deve essere superiore a 6 caratteri",
	"PASSWORD_TOO_LONG": "La password deve essere inferiore a 20 caratteri",
	"FOR_BOATERS_MENU_ITEM": "PER I BARCAIOLI",
	"FOR_MARINAS_MENU_ITEM": "PER I PORTI TURISTICI",
	"FOR_THE_OCEAN_MENU_ITEM": "PER L'OCEANO",
	"LOG_IN_MENU_ITEM": "ACCEDI",
	"SIGN_UP_MENU_ITEM": "REGISTRATI",
	"POPEYE_HOMEPAGE_INSPIRE": "Entra a far parte della comunità di coloro che si impegnano collettivamente a rendere la nautica più sostenibile e gratificante.",
	"PAP_LINK_TEXT": "Nuovo su Pick a Pier? <br>Scopri perché i barcaioli ci adorano →"
}};
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const Section = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  @media only screen and (max-width: 767px) {
    background: ${themeGet('color.6', '#ffffff')};
  }
`;

export const Advantages = styled.ul`
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  background: ${themeGet('color.6', '#ffffff')};
  width: 1093px;
  padding: 85px 111px 93px;
  position: absolute;
  top: -133px;
  border-radius: 16px;
  @media only screen and (max-width: 991px) {
    width: 100%;
    padding: 50px 40px;
  }
  @media only screen and (max-width: 767px) {
    flex-direction: column;
    padding: 69px 40px 26px;
    position: static;
  }
`;

export const Advantage = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (max-width: 767px) {
    margin-bottom: 50px;
  }
`;

export const Text = styled.p`
  max-width: 220px;
  font-size: 21px;
  line-height: 25px;
  font-weight: bold;
  color: ${themeGet('text.8', '#0036b1')};
  @media only screen and (max-width: 767px) {
    max-width: 232px;
  }
`;

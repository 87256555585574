import React from 'react';
import { Button, Chevron } from './BackButtonMobile.style';

const BackButtonMobile = ({onClick = () => {}}) => {
  return (
    <Button onClick={onClick}>
      <Chevron />
    </Button>
  )
}

export default BackButtonMobile;
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { benefits } from '../../../constants/benefits';

import PhoneSlide from '../../../elements/PhoneSlide/PhoneSlide';

import {
  Section,
  Container,
  Title,
  Pagination,
  PaginItem,
  Benefits,
  Benefit,
  TitleMobile,
  BenefitTitle,
  Description,
  Image
} from './SectionAbout.style';

const SectionAbout = () => {
  const { t } = useTranslation();
  const timerRef = useRef(null);

  const [ active, setActive ] = useState(0);

  useEffect(() => {
    timerRef.current = setTimeout(() => {
      const newActive = active === benefits.length - 1 ? 0 : active + 1;
      setActive(newActive);
    }, 3000);
    return () => clearTimeout(timerRef.current);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  return (
    <Section>
      <TitleMobile>{t('POPEYE_HOMEPAGE_RULES_TITLE')}</TitleMobile>
      <Container>
        <Benefits>
          <Title>{t('POPEYE_HOMEPAGE_RULES_TITLE')}</Title>
          {benefits.map(({image, title, description}, index) => (
            <Benefit key={index} className={ active === index ? 'active' : '' } onClick={() => setActive(index)}>
              <BenefitTitle>{t(title)}</BenefitTitle>
              <Description>{t(description)}</Description>
            </Benefit>
          ))}
        </Benefits>
        <Pagination>
          {benefits.map((item, index) => <PaginItem key={index} className={ active === index ? 'active' : '' } />)}
        </Pagination>
        <Image>
          <PhoneSlide alt={benefits[active].title} src={benefits[active].image} />
        </Image>
      </Container>
    </Section>
  )
}

export default SectionAbout;

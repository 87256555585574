import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import stampImg from '../../../images/stamp.png';
import stampMobileImg from '../../../images/stamp_mobile.png';

const flexStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const backgroundStyles = css`
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

export const Section = styled.section`
  width: 100%;
  box-sizing: border-box;
  ${flexStyles};
  padding: 175px 0 93px;
  background: ${themeGet('color.15', '#e3ebed')};
  position: relative;
  @media only screen and (max-width: 767px) {
    padding: 76px 0 101px;
  }
`;

export const Container = styled.div`
  width: 100%;
  box-sizing: border-box;
  max-width: 1056px;
  padding: 0 34px 0;
  ${flexStyles};
  position: relative;
  z-index: 1;
  @media only screen and (max-width: 767px) {
    padding: 0 40px 0;
  }
  @media only screen and (max-width: 480px) {
    padding: 0 14px 0;
  }
`;



export const CardImage = styled.div`
  margin-bottom: 80px;
  img {
    width: 741px;
    height: 518px;
  }
  @media only screen and (max-width: 767px) {
    img {
      width: 340px;
      height: 238px;
    }
  }
`;

export const StampImage = styled.div`
  ${backgroundStyles};
  background-image: url(${stampImg});
  position: absolute;
  bottom: 0;
  right: 0;
  height: 660px;
  width: 780px;
  @media only screen and (max-width: 767px) {
    background-image: url(${stampMobileImg});
    height: 436px;
    width: 294px;
  }
`;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Section, Container, Link } from './SectionLink.style';
import { sanitizeTranslation } from '../../../utils/sanitizeTranslation';
import logo from '../../../images/logo_circle.svg';

const SectionLink = () => {
  const { t } = useTranslation();
  return (
    <Section>
      <Container>
        <img alt='Pick a pier' src={logo} />
        <Link href="https://app.pickapier.com/en" target="_blank">{sanitizeTranslation(t('PAP_LINK_TEXT'))}</Link>
      </Container>
    </Section>
  )
}

export default SectionLink;

import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const Wrapper = styled.div`
  position: relative;
  .language-icon {
    margin-left: 6px;
    margin-top: 6px;
  }
`;

export const Trigger = styled.div`
  display: flex;
  box-sizing: border-box;
  align-items: center;
  cursor: pointer;
  height: 44px;
  font-size: 17px;
  line-height: 20px;
  font-weight: 400;
  text-transform: uppercase;
  padding: 12px;
  color: ${({scrolled}) => scrolled ? themeGet('text.11', '#121A2C') : themeGet('text.1', '#ffffff') };
  margin: 0 8px;
  border-radius: 5px;
  &:hover {
    background-color: rgba(242,242,242,0.1);
  }
  @media only screen and (max-width: 991px) {
    margin: 0;
  }
`;

export const Dropdown = styled.div`
  position: absolute;
  top: calc(100% + 21px);
  right: 0;
  transition: all 0.3s ease;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  min-width: 160px;
  background-color: ${themeGet('color.0', '#eff2f8')};
  &.hide {
    opacity: 0;
    visibility: hidden;
  }
  &.active {
    opacity: 1;
    visibility: visible;
  }
`;

export const LanguageMenu = styled.ul`
  color: ${themeGet('text.0', '#01257A')};
  text-align: left;
`

export const LanguageItem = styled.li`
  cursor: pointer;
  padding: 10px 20px;
  line-height: 24px;
`

import UKFlag from '../images/united-kingdom-flag-icon-32.png';
import FranceFlag from '../images/france-flag-icon-32.png';
import GermanyFlag from '../images/germany-flag-icon-32.png';
import SpainFlag from '../images/spain-flag-icon-32.png';
import ItalyFlag from '../images/italy-flag-icon-32.png';

const locales = {
  en: {
    label: 'English',
    icon: UKFlag
  },
  fr: {
    label: 'Français',
    icon: FranceFlag
  },
  es: {
    label: 'Español',
    icon: SpainFlag
  },
  de: {
    label: 'Deutsch',
    icon: GermanyFlag
  },
  it: {
    label: 'Italiano',
    icon: ItalyFlag
  }
};

export const localeOptions = Object.entries(locales).map(([key]) => key);

export default locales;

import frame1 from '../images/frame_1.png';
import frame2 from '../images/frame_2.png';
import frame3 from '../images/frame_3.png';
import frame4 from '../images/frame_4.png';

export const benefits = [
  {
    image: frame1,
    title: 'POPEYE_HOMEPAGE_RULE1_TITLE',
    description: 'POPEYE_HOMEPAGE_RULE1_DESC'
  },
  {
    image: frame2,
    title: 'POPEYE_HOMEPAGE_RULE2_TITLE',
    description: 'POPEYE_HOMEPAGE_RULE2_DESC'
  },
  {
    image: frame3,
    title: 'POPEYE_HOMEPAGE_RULE3_TITLE',
    description: 'POPEYE_HOMEPAGE_RULE3_DESC'
  },
  {
    image: frame4,
    title: 'POPEYE_HOMEPAGE_RULE4_TITLE',
    description: 'POPEYE_HOMEPAGE_RULE4_DESC'
  }
]

import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import { Section, Container, CardImage, StampImage } from './SectionCard.style';


import cardImg from '../../../images/card.png';

const SectionCard = ({ history, match }) => {
  const { t } = useTranslation();
  return (
    <Section>
      <StampImage />
      <Container>
        <CardImage>
           <img alt='Pick a pier' src={cardImg} />
        </CardImage>
      </Container>
    </Section>
  )
}

export default withRouter(SectionCard);

import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { generateID } from '../../utils/generateID';
import { CheckboxWrapper, Error, CheckMark } from './Fields.style';

const Checkbox = forwardRef(({label, name, errors, ...rest}, ref) => {
  const { t } = useTranslation();
  const id = generateID();
  return (
    <CheckboxWrapper className="checkbox-field">
      <label htmlFor={`${id}_${name}`}>
        {label}
        <input type="checkbox" name={name} id={`${id}_${name}`} ref={ref} {...rest} />
        <CheckMark className="check-mark"/>
      </label>
      {Boolean(errors) && <Error>{t(errors.message)}</Error>}
    </CheckboxWrapper>
  )
});

export default Checkbox;
import axios from 'axios';
import qs from 'qs';

axios.defaults.withCredentials = true;
axios.defaults.headers.common['Accept'] = 'application/json';

export const get = async (url, query) => {
  if (!query) query = '';
  try {
    const response = await call('get', `${url}`, {
      params: {
        ...query
      }
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error.response && error.response.data ? error.response.data : error;
  }
};

export const post = async (url, data) => {
  try {
    const response = await call('post', `${url}`, data);
    return response.data;
  } catch (error) {
    console.error(error);
    return error.response && error.response.data ? error.response.data : error;
  }
};

async function call(func, url, data) {
  const headers = {
    'Content-Type': 'application/json'
  };
  const options = {
    method: func,
    withCredentials: true,
    headers,
    data: func === 'post' ? data : qs.stringify(data),
    params: func === 'get' ? data.params : '',
    url: (process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_ENDPOINT : '/api') + url
  };
  return axios(options);
}

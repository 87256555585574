import React, { useContext } from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { GlobalContext } from '../../providers/GlobalProvider';

import FooterMenu from './FooterMenu/FooterMenu';
import FollowMenu from './FollowMenu/FollowMenu';
import BottomMenu from './BottomMenu/BottomMenu';

import { getMenuColumns, socialMenuItems } from '../../constants/footerMenus';

import smallLogo from '../../images/logo_vertical.png';

import FooterWrapper, {
  Top,
  Logo,
  Bottom,
  Copyright
} from './Footer.style';

const Footer = ({ match }) => {
  const { t } = useTranslation();
  const { termsOfServiceLink, privacyPolicyLink } = useContext(GlobalContext);

  const columnsMenu = getMenuColumns(match.params.locale);

  const bottomMenu = [
    {
      title: t('FOOTER_TERMS'),
      link: termsOfServiceLink,
      amplitude: {
        eventProperties: { type: 'terms' },
        eventName: 'footer_link_clicked'
      }
    },
    {
      title: t('FOOTER_PRIVACY'),
      link: privacyPolicyLink,
      amplitude: {
        eventProperties: { type: 'privacy' },
        eventName: 'footer_link_clicked'
      }
    },
    {
      title: t('FOOTER_GDPR'),
      link: 'https://gdpr-rep.eu/q/17740657',
      amplitude: {
        eventProperties: { type: 'gdpr' },
        eventName: 'footer_link_clicked'
      }
    }
  ];

  const sendToAmplitude = amplitude => {
    // ToDo: implement amplitude events in the future
    console.log(amplitude)
  }

  return (
    <FooterWrapper id='footer'>
      <Top>
        <Logo>
          <NavLink to={match.url}>
            <img src={smallLogo} alt='Pick a Pier' />
          </NavLink>
        </Logo>
        <FooterMenu menu={ columnsMenu } onLinkClick={ amplitude => sendToAmplitude(amplitude) } />
        <FollowMenu menu={ socialMenuItems } onLinkClick={ amplitude => sendToAmplitude(amplitude) } />
      </Top>
      <Bottom>
        <Copyright>{`@ ${new Date().getFullYear()} PICK A PIER LTD. All rights reserved.`}</Copyright>
        <BottomMenu menu={ bottomMenu } onLinkClick={ amplitude => sendToAmplitude(amplitude) } />
      </Bottom>
    </FooterWrapper>
  );
};

export default withRouter(Footer);

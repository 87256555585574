import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const wrapperStyles = css`
  position: relative;
  width: 100%;
  margin-bottom: 40px;
  text-align: left;
`;

export const SelectWrapper = styled.div`
  ${wrapperStyles};
  margin-bottom: 77px;
`;

export const InputWrapper = styled.div`
  ${wrapperStyles};
  height: 54px;
  &.extended {
    margin-bottom: 55px;
    @media only screen and (max-width: 480px) {
      .error-valiadtion {
        padding: 2px 0;
      }
    }
  }
  input {
    width: 100%;
    box-sizing: border-box;
    padding: 15px 21px 13px;
    font-size: 17px;
    height: 54px;
    border-radius: 27px;
    outline: none;
    border: none;
    background-color: ${themeGet('color.0', '#eff2f8')};
    color: ${themeGet('text.3', '#00123D')};
    &::placeholder {
      color: ${themeGet('text.2', '#6e82a8')};
    }
    &[disabled] {
      background-color: ${themeGet('color.11', '#fafafa')};
      color: ${themeGet('text.9', '#545454')};
    }
    @media only screen and (max-width: 767px) {
      background-color: ${themeGet('color.6', '#ffffff')};
      &[disabled] {
        background-color: ${themeGet('color.12', '#4e619e')};
        color: ${themeGet('text.3', '#00123D')};
      }
    }
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance: textfield;
  }
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  ${wrapperStyles};
  label {
    position: relative;
    padding-left: 26px;
    display: block;
    font-size: 16px;
    line-height: 20px;
  }
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked ~ .check-mark::after {
      background-color: ${themeGet('color.4', '#0036b1')};
    }
    @media only screen and (max-width: 767px) {
      &:checked ~ .check-mark::after {
        background-color: ${themeGet('color.6', '#ffffff')};
      }
    }
  }
`;

export const CheckMark = styled.span`
  position: absolute;
  top: 4px;
  left: 0;
  height: 11px;
  width: 11px;
  border-radius: 2px;
  border: 1px solid ${themeGet('color.5', '#000000')};
  &::after {
    content: "";
    background-color: transparent;
    position: absolute;
    left: 2px;
    top: 2px;
    width: 7px;
    height: 7px;
    transition: all 0.3s ease;
  }
  @media only screen and (max-width: 767px) {
    border: 1px solid ${themeGet('color.6', '#ffffff')};
  }
`;

export const Error = styled.span`
  position: absolute;
  top: 100%;
  left: 0;
  color: ${themeGet('error.0', '#F9503D')};
  padding: 2px 0 2px 20px;
`;

export const ArrowDown = styled.div`
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid #1a1a1b;
`

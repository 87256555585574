import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const Wrapper = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 412px;
  box-sizing: border-box;
  padding-bottom: 65px;

  .switch-field {
    margin-top: 34px;
    margin-bottom: 77px;
    &.is-owner {
      margin-bottom: 37px;
    }
  }

  @media only screen and (max-width: 767px) {
    padding-bottom: 40px;
    .switch-field {
      margin-top: 54px;
      margin-bottom: 87px;
    }
  }

  @media only screen and (max-width: 480px) {
    width: 100%;
    max-width: 100%;
    padding: 0 20px 40px;
  }
`;

export const Form = styled.form`
  width: 100%;
  @media only screen and (max-width: 767px) {
    .select-field {
      margin-bottom: 106px;
    }
  }
`;

export const FieldRow = styled.div`
  display: flex;
  .input-field {
    flex-grow: 1;
    margin-right: 11px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

export const NotFound = styled.div`
  button {
    margin-left: 10px;
    color: ${themeGet('text.8', '#0036b1')};
    background-color: transparent;
    font-size: 16px;
    outline: none;
    border: 0;
    padding: 0;
  }
`;

import React, { useState, useEffect } from 'react';
import publicService from '../services/publicService';
import isEmpty from 'lodash/isEmpty'

export const GlobalContext = React.createContext({});

const GlobalProvider = ({ children }) => {
  const [marinas, setMarinas] = useState([]);
  const [termsOfServiceLink, setTermsOfServiceLink] = useState(null);
  const [privacyPolicyLink, setPrivacyPolicy] = useState(null);
  const [filtered, setFiltered] = useState(false);

  const loadInitialData = async () => {
    const response = await publicService.getMarinas();
    setMarinas(response);

    const data = await publicService.getMarinasData();
    if(!isEmpty(data))
    {
      setTermsOfServiceLink(data.terms_of_service);
      setPrivacyPolicy(data.privacy_policy);
    }
  };

  useEffect(() => {
    loadInitialData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!filtered && marinas.length && process.env.REACT_APP_FIREBASE_ENV === 'PRODUCTION') {
      const prodMarinas = marinas.filter(
        m => !(m.mail && m.mail.includes('@pickapier.com'))
      );
      setMarinas(prodMarinas);
      setFiltered(true)
    }
  }, [marinas, filtered]);

  const subscribeGuest = async data => {
    try {
      const res = await publicService.subscribeGuest(data);
      return res;
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <GlobalContext.Provider
      value={{
        marinas,
        termsOfServiceLink,
        privacyPolicyLink,
        subscribeGuest
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalProvider;

import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const titleStyles = css`
  max-width: 422px;
  color: ${themeGet('text.7', '#121A2C')};
`

export const Section = styled.section`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  background: ${themeGet('color.6', '#ffffff')};
  padding: 91px 20px 0;
  @media only screen and (max-width: 767px) {
    padding: 76px 0 0;
    flex-direction: column;
    align-items: center;
  }
`;

export const Container = styled.div`
  width: 1092px;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 767px) {
    flex-direction: column-reverse;
    width: 100%;
  }
`;

export const Title = styled.h2`
  ${titleStyles};
  font-size: 27px;
  line-height: 34px;
  text-align: left;
  margin-bottom: 64px;
  @media only screen and (max-width: 767px) {
    display: none;
  }
`;

export const TitleMobile = styled.h2`
  ${titleStyles};
  font-size: 30px;
  line-height: 33px;
  display: none;
  @media only screen and (max-width: 767px) {
    display: block;
    margin-bottom: 48px;
  }
`;

export const Pagination = styled.ul`
  display: none;
  align-items: center;
  justify-content: center;
  padding: 11px 0 23px;
  @media only screen and (max-width: 767px) {
    display: flex;
  }
`;

export const PaginItem = styled.li`
  width: 6px;
  height: 6px;
  background-color: ${themeGet('color.5', '#000000')};
  border: 3px solid ${themeGet('color.6', '#ffffff')};
  border-radius: 50%;
  opacity: 0.5;
  margin: 4px 5px;
  &.active {
    opacity: 1;
    border: 3px solid ${themeGet('color.5', '#000000')};
  }
`;

export const Benefits = styled.ul`
  padding-top: 20px;
  padding-bottom: 45px;
  max-width: 422px;
  @media only screen and (max-width: 991px) {
    padding-top: 0;
  }
  @media only screen and (max-width: 767px) {
    padding-bottom: 28px;
    max-width: 100%;
  }
`;

export const Benefit = styled.li`
  max-width: 422px;
  margin-bottom: 22px;
  padding-bottom: 22px;
  border-bottom: 1px solid ${themeGet('border.6', '#E0E0E0')};
  text-align: left;
  opacity: 0.5;
  cursor: pointer;
  &.active {
    opacity: 1;
    cursor: default;
    pointer-events: none;
  }
  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
  @media only screen and (max-width: 767px) {
    max-width: 100%;
    display: none;
    text-align: center;
    padding: 0 20px;
    margin-bottom: 0;
    border-bottom: none;
    &.active {
      display: block;
    }
  }
`;

export const BenefitTitle = styled.h3`
  font-size: 21px;
  line-height: 25px;
  color: ${themeGet('text.7', '#121A2C')};
  margin-bottom: 7px;
`;

export const Description = styled.p`
  font-size: 17px;
  line-height: 24px;
  color: ${themeGet('text.6', '#121A2C')};
  @media only screen and (max-width: 767px) {
    height: 92px;
    overflow: hidden;
  }
`;

export const Image = styled.div`
  align-self: end;
  width: 584px;
  display: flex;
  align-items: flex-end;
  @media only screen and (max-width: 767px) {
    width: 100%;
    height: 388px;
    img {
      max-width: 355px;
    }
  }
`;

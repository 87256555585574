import config from '../settings/firebaseServiceConfig';
import firebase from 'firebase/app';

class firebaseService {
  zeroPad(num, places) {
    let zero = places - num.toString().length + 1;
    return Array(+(zero > 0 && zero)).join('0') + num;
  }

  importMethods(arr) {
    Object.keys(arr).forEach(key => (this[key] = arr[key].bind(this)));
  }

  init(success) {
    if (Object.entries(config).length === 0 && config.constructor === Object) {
      if (process.env.NODE_ENV === 'development') {
        console.warn(
          'Missing Firebase Configuration at src/settings/firebaseServiceConfig.js'
        );
      }
      success(false);
      return;
    }

    if (firebase.apps.length) {
      return;
    }
    firebase.initializeApp(config);

    this.logged_in = false;
    const _this = this;
    import('firebase/auth')
      .then(() => {
        _this.auth = firebase.auth();
        this.GoogleAuthProvider = firebase.auth.GoogleAuthProvider;
        _this.FacebookAuthProvider = firebase.auth.FacebookAuthProvider;
        success(true);
      })
      .catch(error => {
        console.error('Unable to lazy-load firebase/auth:', error);
      });

    import('firebase/analytics')
      .then(() => {
        _this.analytics = firebase.analytics();
      })
      .catch(error => {
        console.error('Unable to lazy-load firebase/analytics:', error);
      });
    import('firebase/performance')
      .then(() => {
        // this.performance = firebase.performance();
      })
      .catch(error => {
        console.error('Unable to lazy-load firebase/performance:', error);
      });
  }
}

const instance = new firebaseService();

export default instance;

import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const Button = styled.button`
  margin-right: auto;
  padding: 0;
  background: transparent;
`;

export const Chevron = styled.i`
  & {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs, 1));
    width: 22px;
    height: 20px;
    border: 1px solid transparent;
    border-radius: 100px;
    overflow: hidden;
  }
  &::after {
    content: '';
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 14px;
    height: 14px;
    border-bottom: 3px solid;
    border-right: 3px solid;
    transform: rotate(135deg);
    right: 4px;
    top: 2px;
    border-color: ${themeGet('color.6', '#ffffff')};
  }
`;
import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

export const Wrapper = styled.div`
  padding: 0 21px;
  width: 100%;
  box-sizing: border-box;
  text-align: left;
`;
export const Title = styled.h3`
  font-size: 17px;
  line-height: 26px;
  margin-bottom: 28px;
  @media only screen and (max-width: 767px) {
    margin-bottom: 18px;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  padding: 0;
  background: transparent;
  color: ${themeGet(" text.7", "#121A2C")};
  margin-right: 56px;

  &.disabled-button {
    color: ${themeGet('text.10', "#1010104D")}
  }
  @media only screen and (max-width: 767px) {
    color: ${themeGet("text.1", "#ffffff")};
  }
`;

export const Circle = styled.div`
  width: 27px;
  height: 27px;
  border-radius: 50%;
  background: ${themeGet("color.6", "#ffffff")};
  margin-right: 15px;
  position: relative;
  box-sizing: border-box;
  border: 3px solid ${themeGet("border.1", "#000000")};

  &:after {
    content: "";
    position: absolute;
    top: 3px;
    left: 3px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: transparent;
    transition: all 0.3s ease;
  }
  &.active {
    &:after {
      background: ${themeGet("color.4", "#0036b1")};
    }
  }

  @media only screen and (max-width: 767px) {
    border: 3px solid ${themeGet("border.2", "#ffffff")};
  }
`;

export const Label = styled.span`
  font-size: 17px;
  line-height: 26px;
`;

import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 118px;
  @media only screen and (max-width: 991px) {
    padding: 0 40px;
  }

  @media only screen and (max-width: 767px) {
    padding: 0 40px 52px;
    margin-top: 99px;
  }

  @media only screen and (max-width: 480px) {
    padding: 0 20px 52px;
  }
`;

export const Title = styled.h2`
  font-size: 45px;
  line-height: 54px;
  margin-bottom: 35px;
  @media only screen and (max-width: 767px) {
    font-size: 26px;
    line-height: 29px;
    margin-bottom: 33px;
  }
`;

export const Description = styled.div`
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 57px;
  @media only screen and (max-width: 767px) {
    font-size: 17px;
    line-height: 22px;
    margin-bottom: 36px;
  }
`;
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 565px;
  max-width: 100%;
  @media only screen and (max-width: 1440px) {
    width: 465px;
  }
  @media only screen and (max-width: 991px) {
    width: 100%;
    margin-bottom: 14px;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Title = styled.h5`
  color: ${themeGet('text.11', '#121A2C')};
  font-weight: bold;
  font-size: 17px;
  line-height: 24px;
  margin-bottom: 23px;
  margin-top: 0;
  @media only screen and (max-width: 991px) {
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
  }
`;

export const Menu = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const MenuItem = styled.li`
  height: 40px;
  padding: 0;
  margin-bottom: 6px;
  font-size: 14px;
  line-height: 22px;
  a {
    color: ${themeGet('text.12', '#194AB9')};
    &:hover {
      opacity: 0.8;
    }
  }
  @media only screen and (max-width: 767px) {
    height: 31px;
    font-size: 11px;
    line-height: 13px;
    a {
      color: ${themeGet('text.11', '#121A2C')};
      &:hover {
        opacity: 0.8;
      }
    }
  }
`;

import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const Section = styled.section`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 298px 0 85px;
  background: ${themeGet('color.13', '#f2f2f2')};
  @media only screen and (max-width: 767px) {
    padding: 149px 0 100px;
  }
`;

export const Container = styled.div`
  width: 100%;
  box-sizing: border-box;
  max-width: 1056px;
  padding: 0 34px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .popeye-head {
    margin-bottom: 28px;
  }
  @media only screen and (max-width: 767px) {
    padding: 0 40px 0;
  }
  @media only screen and (max-width: 480px) {
    padding: 0 20px 0;
  }
`;

export const Description = styled.p`
  max-width: 683px;
  font-size: 21px;
  line-height: 34px;
  text-align: center;
  color: ${themeGet('text.6', '#121A2C')};
  @media only screen and (max-width: 767px) {
    max-width: 347px;
  }
`;

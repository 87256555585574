import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const flexStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Section = styled.section`
  width: 100%;
  box-sizing: border-box;
  ${flexStyles};
  padding: 117px 0 116px;
  background: ${themeGet('color.6', '#ffffff')};
  @media only screen and (max-width: 767px) {
    padding: 85px 0 68px;
  }
`;

export const Container = styled.div`
  width: 100%;
  box-sizing: border-box;
  max-width: 1056px;
  padding: 0 34px 0;
  ${flexStyles};
  img {
    width: 55px;
    height: 55px;
    margin-bottom: 18px;
  }
  @media only screen and (max-width: 767px) {
    padding: 0 40px 0;
  }
  @media only screen and (max-width: 480px) {
    padding: 0 20px 0;
  }
`;

export const Link = styled.a`
  font-size: 17px;
  line-height: 24px;
  text-align: center;
  color: ${themeGet('text.7', '#121A2C')};
  font-weight: 400;
  br {
    display: none;
  }
  @media only screen and (max-width: 767px) {
    max-width: 359px;
    br {
      display: inline;
    }
  }
`;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Section, Container, Description } from './SectionNote.style';

const SectionNote = () => {
  const { t } = useTranslation();
  return (
    <Section>
      <Container>
        <Description>{t('POPEYE_HOMEPAGE_INSPIRE')}</Description>
      </Container>
    </Section>
  )
}

export default SectionNote;

export const theme = {
  // breakpoints: ['481px', '768px', '992px', '1201px', '1441px'],
  primary: [
    '#01257a', // 0: Default
    '#194AB9', // 1: Next
  ],
  color: [
    '#eff2f8', // 0: Input
    '#2ec4b6', // 1: Button
    '#5AD8CC', // 2: Button hover mobile
    '#697EB0', // 3: Button disabled
    '#0036b1', // 4: Buttons, Checkbox and Switch Active
    '#000000', // 5: Black
    '#ffffff', // 6: White
    '#e7ecf7', // 7: Lilac
    '#F5F5F5', // 8: Secondary button hover mobile
    '#020300', // 9: Dark
    '#345cc0', // 10: Button hover
    '#fafafa', // 11: Input disabled
    '#4e619e', // 12: Input disabled mobile
    '#f2f2f2', // 13: Light
    '#181818', // 14: Pledge
    '#e3ebed', // 15: Card
  ],
  error: [
    '#FF6259', // 0: Error
  ],
  text: [
    '#01257A', // 0: Main
    '#ffffff', // 1: White
    '#6e82a8', // 2: Placeholder
    '#00123D', // 3: Input
    '#1a1a1b', // 4: Button and black text
    '#67b5ff', // 5: Link mobile
    '#121A2C', // 6: Dark main text
    '#121A2C', // 7: Dark main text
    '#0036b1', // 8: Link
    '#545454', // 9: Disable
    '#1010104D', // 10: Gray
    '#121A2C', // 11: Dark main text
    '#194AB9', // 12: Blue main text
    '#828282', // 13: Gray main text
  ],
  border: [
    '#bfbfbf', // 0: Gray border
    '#000000', // 1: Black border
    '#ffffff', // 2: White border
    '#00ae42', // 3: Green border
    '#bdbdbd', // 4: Also gray border
    '#121A2C', // 5: Dark main border
    '#E0E0E0', // 6: light border
  ],
  fonts: {
    primary: 'Lato, sans-serif'
  }
};

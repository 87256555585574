import React, { useEffect, useContext } from 'react';
import { LayoutContext } from './LayoutProvider';
import firebaseService from '../services/firebaseService';

export const AuthContext = React.createContext({});

const AuthProvider = ({ children }) => {
  const [, dispatch] = useContext(LayoutContext);

  useEffect(() => {
    const init = () =>
      Promise.all([
        firebaseCheck()
      ]).then(() => {});
    init();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const firebaseCheck = () => {
    new Promise((resolve, reject) => {
      firebaseService.init(success => {
        if (!success) {
          resolve();
        }
      });
    });
  };

  const signUp = async params => {
    dispatch({ type: 'ENABLE_LOADING' });
    return new Promise((resolve, reject) => {
      const { email, password, first_name, last_name } = params;
      firebaseService._logged_in = false;
      if (firebaseService.auth) {
        firebaseService.auth
          .createUserWithEmailAndPassword(email, password)
          .then(({ user }) => {
            dispatch({ type: 'DISABLE_LOADING' });
            user.updateProfile({
                displayName: `${first_name} ${last_name}`,
              })
              .catch(function (error) {
                console.log('***ERROR_UPDATING_PROFILE');
              });
            resolve(user);
          })
          .catch(error => {
            dispatch({ type: 'DISABLE_LOADING' });
            if (error.code === 'auth/invalid-api-key') {
              console.log('api key error');
            }
            reject(error);
          });
      }
    });
  };

  return (
    <AuthContext.Provider value={{ signUp }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 412px;
  margin: 8px auto 0;
  @media only screen and (max-width: 991px) {
    padding: 0 40px;
  }

  @media only screen and (max-width: 767px) {
    padding: 0 40px 52px;
  }

  @media only screen and (max-width: 480px) {
    padding: 0 20px 52px;
  }
`;

export const Form = styled.form`
  padding-top: 100px;
  width: 100%;
  position: relative;
  .invitationCode::placeholder {
    text-align: center;
  }
  @media only screen and (max-width: 767px) {
    padding-top: 92px;
  }
  @media only screen and (max-width: 480px) {
    .checkbox-field {
      margin-bottom: 56px
    }
  }
`;

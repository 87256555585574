import React, { Fragment, useState, useEffect, useContext, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { isMobile } from 'react-device-detect';

import { LayoutContext } from '../../providers/LayoutProvider';
import { GlobalContext } from '../../providers/GlobalProvider';
import { PopeyeContext } from '../../providers/PopeyeProvider';

import PopeyeTitle from '../../elements/PopeyeTitle/PopeyeTitle';
import Switch from '../../elements/Switch/Switch';
import Input from '../../elements/Fields/Input';
import SelectField from '../../elements/Fields/Select';
import SubmitButton from '../../elements/SubmitButton/SubmitButton';
import BackButton from '../../elements/BackButton/BackButton';
import Fade from '../../elements/Transition/Fade';
import isNull from 'lodash/isNull'

import { vesselFormSchema } from '../../constants/schemas';
import { SIGNUP_PAGE, WAITING_PAGE, CONGRATS_PAGE } from '../../constants/routes';

import { Wrapper, Form, FieldRow, NotFound } from './Vessel.style';

const Vessel = ({history, match, location}) => {
  const selectRef = useRef();
  const { t } = useTranslation();

  const { marinas } = useContext(GlobalContext);
  const { addVessel, homeMarinaId: verifiedHomeMarinaId } = useContext(PopeyeContext);
  const [{ loading }] = useContext(LayoutContext);

  const [ newHomeMarina, setNewHomeMarina ] = useState(null);
  const [ menuIsOpen, setMenuIsOpen ] = useState(false);
  const [ options, setOptions ] = useState(marinas.map(({ home_marina_id, home_marina_name }) => ({ value: home_marina_id, label: home_marina_name })));

  const wasInvitedByMarina = !isNull(verifiedHomeMarinaId);
  const [ isOwner, setIsOwner ] = useState(() => wasInvitedByMarina ? true : null);

  const {
    vesselName,
    vesselLength,
    vesselBeam,
    vesselDraft,
    homeMarinaId,
  } = vesselFormSchema;

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue
  } = useForm();

  const onSubmit = async (formProps) => {
    if(loading) return;

    const {
      vesselName,
      vesselLength,
      vesselBeam,
      vesselDraft,
      homeMarinaId,
      homeMarinaName
    } = formProps;

    let vessel = {
      name: vesselName,
      length: vesselLength,
      beam: vesselBeam,
      draft: vesselDraft,
      home_marina_name: homeMarinaId ? homeMarinaId.label : homeMarinaName,
      home_marina_id: homeMarinaId && homeMarinaId.value
    };
   
    const res = await addVessel(vessel);
    if(res.error_type) {
      console.error(res.error_type);
    } else {
      const { popeye_loyalty_status } = res;
      if(popeye_loyalty_status === 'ON_WAITING_LIST') {
        history.replace(`/${match.params.locale}${WAITING_PAGE}`);
      } else {
        history.replace(`/${match.params.locale}${CONGRATS_PAGE}`);
      }
    }
  };

  const onWaiting = async () => {
    await addVessel();
    history.replace(`/${match.params.locale}${WAITING_PAGE}`);
  }

  const onAddNewHomeMarina = e => {
    e.preventDefault();
    setOptions([...options, { label: newHomeMarina }])
    setValue('homeMarinaName', newHomeMarina, { shouldValidate: true });
  }

  const onOpenMenu = () => {
    setMenuIsOpen(true);
  }

  const onCloseMenu = () => {
    setMenuIsOpen(false);
  }

  useEffect(() => {
    if (verifiedHomeMarinaId) {
      const option = options.find(option => option.value === verifiedHomeMarinaId)
      setValue('homeMarinaId', option)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verifiedHomeMarinaId])

  return (
    <Fade>
      <Wrapper>
        <PopeyeTitle />
        <Switch
          className={isOwner ? 'is-owner' : ''}
          title='POPEYE_REGISTRATION_SECOND_STEP_OWNER'
          labelOn='BOAT_OWNER_YES'
          labelOff='BOAT_OWNER_NO'
          constantValue={wasInvitedByMarina}
          value={isOwner}
          onChange={setIsOwner}
        />
        {isOwner ? (
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Input
              placeholder={vesselName.placeholder}
              errors={errors.vesselName}
              {...register('vesselName', vesselName.options)}
            />
            <FieldRow>
              <Input
                type="number"
                step="0.0001"
                placeholder={vesselLength.placeholder}
                errors={errors.vesselLength}
                {...register('vesselLength', vesselLength.options)}
              />
              <Input
                type="number"
                step="0.0001"
                placeholder={vesselBeam.placeholder}
                errors={errors.vesselBeam}
                {...register('vesselBeam', vesselBeam.options)}
              />
              <Input
                type="number"
                step="0.0001"
                placeholder={vesselDraft.placeholder}
                errors={errors.vesselDraft}
                {...register('vesselDraft', vesselDraft.options)}
              />
            </FieldRow>
            <Controller
              name="homeMarinaId"
              control={control}
              rules={homeMarinaId.options}
              render={({ field }) => {
                return (
                  <SelectField
                    selectRef={selectRef}
                    placeholder={homeMarinaId.placeholder}
                    errors={errors.homeMarinaId}
                    options={options}
                    menuIsOpen={menuIsOpen}
                    openMenu={onOpenMenu}
                    closeMenu={onCloseMenu}
                    onInputChange={value => setNewHomeMarina(value)}
                    noOptionsMessage={() => (
                      <NotFound>
                        {t('NO_RESULTS_FOUND_SEARCH_AGAIN_OR_SELECT')}
                        <button onClick={onAddNewHomeMarina}>{t('ADD')}</button>
                      </NotFound>
                    )}
                    {...field}
                  />
                )
              }}
            />
            <SubmitButton
              type="submit"
              label={t('POPEYE_REGISTRATION_SECOND_STEP_CTA')}
              isLoading={loading}
            />
          </Form>
        ) : (
          <Fragment>
            <SubmitButton
              label={t('POPEYE_REGISTRATION_SECOND_STEP_CTA')}
              isLoading={loading}
              onClick={onWaiting}
              disabled={isNull(isOwner)}
            />
          </Fragment>
        )}
        {!isMobile && <BackButton label={t('POPEYE_REGISTRATION_THIRD_STEP_BACK')} onClick={() => history.goBack()}/>}
      </Wrapper>
    </Fade>
  )
}

export default withRouter(Vessel);
import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { PopeyeContext } from '../../providers/PopeyeProvider';

import SubmitButton from '../../elements/SubmitButton/SubmitButton';
import Fade from '../../elements/Transition/Fade';
import { sanitizeTranslation } from '../../utils/sanitizeTranslation';
import { Wrapper, Title, Description } from './Congrats.style';

const Congrats = ({match}) => {
  const { t } = useTranslation();
  const { link } = useContext(PopeyeContext);
  const onComplete = () => {
    if(link) {
      window.location.href = link;
    } else {
      window.location.href = `https://boaters.pickapier.com/${match.params.locale}`;
    }
  }
  return (
    <Fade>
      <Wrapper>
        <Title>{sanitizeTranslation(t('POPEYE_REGISTRATION_THIRD_STEP_COMPLETE_TITLE'))}</Title>
        <Description>{sanitizeTranslation(t('POPEYE_REGISTRATION_THIRD_STEP_COMPLETE_BODY'))}</Description>
        <SubmitButton
          label={t('POPEYE_REGISTRATION_THIRD_STEP_COMPLETE_CTA')}
          onClick={onComplete}/>
      </Wrapper>
    </Fade>
  )
}

export default withRouter(Congrats);
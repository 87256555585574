import React from 'react';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import SubmitButton from '../../elements/SubmitButton/SubmitButton';
import Fade from '../../elements/Transition/Fade';
import PopeyeTitle from '../../elements/PopeyeTitle/PopeyeTitle';

import { VESSEL_PAGE, REFERRAL_CODE_PAGE } from '../../constants/routes';

import { Wrapper, Description } from './Register.style';

const Register = ({ match, history }) => {
  const { t } = useTranslation();

  return (
    <Fade>
      <Wrapper>
        <PopeyeTitle />
        <Description>{t('POPEYE_REGISTRATION_CODE_EXPLANATION')}</Description>
        <SubmitButton
          label={t('POPEYE_REGISTRATION_CODE_PRIMARY_CTA')}
          onClick={() => history.push(`/${match.params.locale}${REFERRAL_CODE_PAGE}`)}
        />
        <SubmitButton
          label={t('POPEYE_REGISTRATION_CODE_SECONDARY_CTA')}
          onClick={() => history.push(`/${match.params.locale}${VESSEL_PAGE}`)}
          mode="secondary"
        />
      </Wrapper>
    </Fade>
  )
}

export default withRouter(Register);
import { get, post } from '../utils/api';

const publicService = {
  getMarinas: async function () {
    return await get('/public/home_marinas');
  },
  getMarinasData: async function () {
    return await get('/public/marinas_data');
  },
  subscribeGuest: async function (data) {
    return await post('/guest/boater_list/subscribe', data);
  }
};

export default publicService;

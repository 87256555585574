import React, { useState, useEffect } from 'react';

import { Transition } from 'react-transition-group';

const duration = 300;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
  width:' 100%'
}

const transitionStyles = {
  entering: { opacity: 1 },
  entered:  { opacity: 1 },
  exiting:  { opacity: 0 },
  exited:  { opacity: 0 },
};

const Fade = ({ in: inProp, children }) => {
  const [fade, setFade] = useState(false);
  useEffect(() => {
    setFade(true);
    return () => {
      setFade(false);
    }
  },[children])
  return (
    <Transition in={fade} timeout={duration}>
      {state => (
        <div style={{
          ...defaultStyle,
          ...transitionStyles[state]
        }}>
          {children}
        </div>
      )}
    </Transition>
  )
};

export default Fade;
import styled from 'styled-components';

export const Wrapper = styled.div`
  margin: 99px auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 480px;
  img {
    max-width: 354px;
  }

  @media only screen and (max-width: 767px) {
    padding: 0 28px;
  }

  @media only screen and (max-width: 480px) {
    img {
      max-width: 319px;
    }
  }
`;

export const Title = styled.h2`
  font-size: 38px;
  line-height: 44px;
  font-weight: bold;
  margin-bottom: 36px;
  max-width: 382px;
  @media only screen and (max-width: 767px) {
    max-width: 355px;
    font-size: 26px;
    line-height: 29px;
    margin-bottom: 42px;
  }
`;

export const Description = styled.p`
  font-size: 20px;
  line-height: 26px;
  margin-top: 37px;
  @media only screen and (max-width: 767px) {
    font-size: 17px;
    line-height: 19px;
    margin-top: 44px;
  }
`;

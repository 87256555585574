import React, { useState, useContext } from 'react';
import { LayoutContext } from './LayoutProvider';
import popeyeService from '../services/popeyeService';

export const PopeyeContext = React.createContext({});

const PopeyeProvider = ({ children }) => {
  const [, dispatch] = useContext(LayoutContext);

  const [ link, setLink ] = useState(null);
  const [ boaterId, setBoaterId ] = useState(null);
  const [ vesselId, setVesselId ] = useState(null);
  const [ fillVesselDetails, setFillVesselDetails ] = useState(true);
  const [ popeyeLoyaltyStatus, setPopeyeLoyaltyStatus ] = useState(null);
  const [ firstName, setFirstName ] = useState(null);
  const [ lastName, setLastName ] = useState(null);
  const [ email, setEmail ] = useState(null);

  const [ homeMarinaId, setHomeMarinaId ] = useState(null);

  const addBoater = async (data) => {
    dispatch({ type: 'ENABLE_LOADING' });
    const { first_name, last_name, email } = data;
    try {
      const res = await popeyeService.addBoater(data);
      dispatch({ type: 'DISABLE_LOADING' });
      if(!res.error_type) {
        const {
          boater_id,
          link,
          vessel_id,
          fill_vessel_details,
          popeye_loyalty_status,
          home_marina_id
        } = res;

        setLink(link);
        setBoaterId(boater_id);
        setVesselId(vessel_id);
        setFillVesselDetails(fill_vessel_details);
        setPopeyeLoyaltyStatus(popeye_loyalty_status);
        setFirstName(first_name);
        setLastName(last_name);
        setEmail(email);

        /**
         * This field is optional,
         * only if the marina invited the boater it will be in the response
         */
        if (home_marina_id) 
          setHomeMarinaId(home_marina_id)
      }
      return res;
    } catch (err) {
      dispatch({ type: 'DISABLE_LOADING' })
      console.error(err);
    }
  };

  const addVessel = async (vessel) => {
    dispatch({ type: 'ENABLE_LOADING' });
    try {
      const res = await popeyeService.addVessel({
        boater_id: boaterId,
        vessel_id: vesselId,
        vessel
      });
      dispatch({ type: 'DISABLE_LOADING' });
      return res;
    } catch (err) {
      dispatch({ type: 'DISABLE_LOADING' })
      console.error(err);
    }
  };

  // const activatePopeye = async ({ boater_id, signature_attachment_id }) => {
  //   dispatch({ type: 'ENABLE_LOADING' });
  //   try {
  //     const res = await popeyeService.activate({
  //       boater_id,
  //       signature_attachment_id
  //     });
  //     dispatch({ type: 'DISABLE_LOADING' });
  //     return res;
  //   } catch (err) {
  //     dispatch({ type: 'DISABLE_LOADING' })
  //     console.error(err);
  //   }
  // };

  return (
    <PopeyeContext.Provider
      value={{
        link,
        boaterId,
        vesselId,
        fillVesselDetails,
        popeyeLoyaltyStatus,
        homeMarinaId,
        addBoater,
        addVessel,
        firstName,
        lastName,
        email
      }}
    >
      {children}
    </PopeyeContext.Provider>
  );
};

export default PopeyeProvider;

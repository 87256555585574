import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { localeOptions } from './locales';
import take from 'lodash/take'
export const availableLanguages = ['en', 'fr', 'de', 'es', 'it'];

const resources = {
  en: require('../translations/en'),
  fr: require('../translations/fr'),
  de: require('../translations/de'),
  es: require('../translations/es'),
  it: require('../translations/it')
};

let arr = window.location.href.split('/#/')[1]
let lang = take(arr, 2).join('');
  
if (!availableLanguages.includes(lang))
  lang = 'en';


if (lang !== 'en')
  resources[lang] = require(`../translations/${lang}`)


i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en',
    fallbackLng: 'en',
    whitelist: localeOptions,
    detection: {
      order: ['path'],
      lookupFromPathIndex: 0,
      checkWhitelist: true
    },
    keySeparator: false, // we do not use keys in form messages.welcome
    escapeInterpolation: false,
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    react: {
      transEmptyNodeValue: '',
      transSupportBasicHtmlNodes: true
    }
  });

export default i18n;

import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { ReactSVG } from 'react-svg'

import locales, { localeOptions } from '../../../settings/locales';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import more from '../../../images/more.svg';
import moreScrolled from '../../../images/more_scrolled.svg';

import { Wrapper, Trigger, Dropdown, LanguageMenu, LanguageItem } from './Language.style.js';

const Language = ({i18n, location, match, history, scrolled}) => {
  const dropdownRef = useRef(null);
  const [visible, setVisible] = useState(false);

  const toggleLanguages = () => setVisible(!visible);
  const closeLanguages = () => setVisible(false);

  useOnClickOutside(dropdownRef, () => setVisible(false));

  useEffect(() => {
    if(!match.params.locale) {
      changeLanguage('en')
    } else {
      changeLanguage(match.params.locale)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeLanguage = lng => {
    // change language
    i18n.changeLanguage(lng);
    // change route
    const ret = match.params.locale ? location.pathname.replace(`/${match.params.locale}`, '') : '';
    const newPathname = `/${lng}${ret}`;
    history.replace({
      pathname: newPathname,
      search: location.search
    });
  }

  return (
    <Wrapper ref={dropdownRef}>
      <Trigger onClick={toggleLanguages} scrolled={ scrolled }>
        {i18n.language}
        <ReactSVG className="language-icon" src={scrolled ? moreScrolled : more} />
      </Trigger>
      <Dropdown className={visible ? 'active' : 'hide'}>
        <LanguageMenu>
          {localeOptions.map((option, index) => (
            <LanguageItem
              key={index}
              onClick={e => {
                e.preventDefault();
                changeLanguage(option);
                closeLanguages();
              }}
            >
              {locales[option] ? locales[option].label : locales.en.label}
            </LanguageItem>
          ))}
        </LanguageMenu>
      </Dropdown>
    </Wrapper>
    
  )
}

export default withRouter(Language);
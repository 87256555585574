import React from 'react';

import SectionMain from './SectionMain/SectionMain';
import SectionAdvantages from './SectionAdvantages/SectionAdvantages';
import SectionDescription from './SectionDescription/SectionDescription';
import SectionNote from './SectionNote/SectionNote';
import SectionAbout from './SectionAbout/SectionAbout';
import SectionPledge from './SectionPledge/SectionPledge';
import SectionLink from './SectionLink/SectionLink';
import SectionCard from './SectionCard/SectionCard';

import Footer from '../Footer/Footer';

import { Wrapper } from './Home.style';

const Home = ({i18n}) => {
  return (
    <Wrapper>
      <SectionMain />
      <SectionAdvantages />
      <SectionDescription />
      <SectionAbout />
      <SectionNote />
      <SectionPledge />
      <SectionLink />
      <SectionCard />
      <Footer />
    </Wrapper>
  )
}

export default Home;

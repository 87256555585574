import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import backgroundImg from '../../../images/pledge.png';

const backgroundStyles = css`
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`;

const flexStyles = css`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Section = styled.section`
  width: 100%;
  box-sizing: border-box;
  ${flexStyles};
  padding: 59px 0 9px;
  background-color: ${themeGet('color.14', '#181818')};
  @media only screen and (max-width: 767px) {
    padding: 68px 0 74px;
  }
`;

export const Content = styled.div`
  ${flexStyles};
  position: relative;
  top: 31px;
  @media only screen and (max-width: 767px) {
    top: 0;
  }
`;

export const Title = styled.h3`
  font-size: 33px;
  line-height: 40px;
  margin-bottom: 26px;
  color: ${themeGet('text.1', '#ffffff')};
  br {
    display: none;
  }
  @media only screen and (max-width: 767px) {
    margin-bottom: 24px;
    br {
      display: inline;
    }
  }
  @media only screen and (max-width: 480px) {
    max-width: 373px;
  }
`;

export const Description = styled.p`
  max-width: 674px;
  font-size: 17px;
  line-height: 24px;
  color: ${themeGet('text.1', '#ffffff')};
  @media only screen and (max-width: 767px) {
    max-width: 440px;
  }
  @media only screen and (max-width: 480px) {
    max-width: 332px;
  }
`;

export const Image = styled.div`
  ${backgroundStyles};
  background-image: url(${backgroundImg});
  height: 807px;
  width: 100%;
  @media only screen and (max-width: 991px) {
    height: 600px;
  }
  @media only screen and (max-width: 767px) {
    height: 299px;
  }
`;

import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const Wrapper = styled.li`
  ${({ desktop, mobile }) => {
    if(desktop && !mobile ) {
      return css`
        display: block;
        @media only screen and (max-width: 991px) {
          display: none;
        }
      `
    } else if(!desktop && mobile) {
      return css`
        display: none;
        @media only screen and (max-width: 991px) {
          display: block;
        }
      `
    } else {
      return css`display: block;`
    }
  }}
  ${({ mode }) => {
    switch(mode) {
      case 'white':
        return css`
          margin: 0 5px 0 8px;
        `
      case 'outlined':
        return css`
          margin: 0 0 0 5px;
        `
      default:
        return css`
          margin: 0 8px;
          @media only screen and (max-width: 991px) {
            margin: 0;
          }
        `
    }
  }}
`

export const Link = styled.a`
  display: block;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 17px;
  line-height: 20px;
  white-space: nowrap;
  font-weight: 400;
  height: 44px;
  padding: 12px;
  border-radius: 5px;
  ${({ mode, scrolled }) => {
    switch(mode) {
      case 'white':
        return css`
          min-width: 74px;
          color: ${themeGet('text.11', '#121A2C')};
          background-color: ${themeGet('color.6', '#ffffff')};
          border: 1px solid;
          border-color: ${scrolled ? themeGet('border.5', '#121A2C') : themeGet('border.2', '#ffffff')};
          &:hover {
            background-color: rgba(242,242,242);
          }
        `
      case 'outlined':
        return css`
          color: ${themeGet('text.1', '#ffffff')};
          background-color: ${scrolled ? themeGet('primary.1', '#194AB9') : 'rgba(242, 242, 242, 0.1)'};
          border: 1px solid;
          border-color: ${scrolled ? themeGet('primary.1', '#194AB9') : themeGet('border.2', '#ffffff')};
          &:hover {
            background-color: ${scrolled ? themeGet('color.4', '#0036b1') : 'rgba(242, 242, 242, 0.1)'};
          }
        `
      default:
        return css`
          color: ${scrolled ? themeGet('text.11', '#121A2C') : themeGet('text.1', '#ffffff') };
          &:hover {
            background-color: ${scrolled ? 'rgba(242,242,242)' : 'rgba(242,242,242,0.1)'};
          }
          ${({ scrolled }) => {
            scrolled && css`
              color: ${themeGet('text.11', '#121A2C')};
            `;
          }}
        `
    }
  }}
`

import React, { Fragment } from 'react';
import { ReactSVG } from 'react-svg'

import Loader from '../Loader/Loader';
import { Button } from './SubmitButton.style';

const SubmitButton = ({
  className = '',
  type="button",
  disabled = false,
  label,
  icon,
  onClick=() => {},
  mode="primary",
  isLoading
}) => {
  return (
    <Button className={`${className} ${mode}`} disabled={disabled} type={type} onClick={onClick}>
      {isLoading ? <Loader /> : (
        <Fragment>
          {icon && <ReactSVG className="button-icon" src={icon} />}
          {label}
        </Fragment>
      )}
    </Button>
  )
}

export default SubmitButton;
import React, { createContext, useContext, useReducer } from 'react';
export const LayoutContext = createContext();

const initialState = {
  pledgeIndex: 0,
  loading: false
};

function reducer(state, action) {
  switch (action.type) {
    case 'INCREASE_PLEDGE_INDEX':
      return {
        ...state,
        pledgeIndex: ++state.pledgeIndex
      };
    case 'DECREASE_PLEDGE_INDEX':
      return {
        ...state,
        pledgeIndex: --state.pledgeIndex
      };
    case 'ENABLE_LOADING':
      return {
        ...state,
        loading: true
      };
    case 'DISABLE_LOADING':
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}

export default function LayoutProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <LayoutContext.Provider value={[state, dispatch]}>
      {children}
    </LayoutContext.Provider>
  );
}

export const useStateValue = () => useContext(LayoutContext);

import React from 'react';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';

import Fade from '../../elements/Transition/Fade';

import group from '../../images/group.png';
import groupMobile from '../../images/group_mobile.png';
import { Wrapper, Title, Description } from './WaitingList.style';

const WaitingList = () => {
  const { t } = useTranslation();
  return (
    <Fade>
      <Wrapper>
        <Title>{t('POPEYE_REGISTRATION_WAITLIST_TITLE')}</Title>
        <img alt='members' src={isMobile ? groupMobile : group} />
        <Description>{t('POPEYE_REGISTRATION_WAITLIST_BODY')}</Description>
      </Wrapper>
    </Fade>
  )
}

export default WaitingList;
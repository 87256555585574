import React from 'react';
import { ReactSVG } from 'react-svg'
import { Button } from './BackButton.style';

const BackButton = ({type="button", label, icon, onClick=() => {} }) => {
  return (
    <Button type={type} onClick={onClick}>
      {icon && <ReactSVG src={icon} />}
      {label && label}
    </Button>
  )
}

export default BackButton;
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  line-height: 34px;
  font-size: 19px;
  padding: 10px 20px;
  height: 54px;
  border-radius: 27px;
  width: 100%;
  max-width: 412px;
  transition: all 0.3s ease;
  outline: none;
  margin-bottom: 14px;
  white-space: nowrap;
  color: ${themeGet('text.1', '#ffffff')};
  border: 1px solid ${themeGet('color.4', '#0036b1')};
  .button-icon {
    margin-top: 10px;
    margin-right: 18px;
  }
  &.primary {
    background: ${themeGet('color.4', '#0036b1')};
  }
  &.secondary {
    color: ${themeGet('text.8', '#0036b1')};
    background: ${themeGet('color.6', '#ffffff')};
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed
  }
  &:hover,
  &:active,
  &:target {
    &.primary {
      background: ${themeGet('color.10', '#345cc0')};
      border: 1px solid ${themeGet('color.10', '#345cc0')};
    }
    &.secondary {
      background: ${themeGet('color.10', '#345cc0')};
      border: 1px solid ${themeGet('color.10', '#345cc0')};
      color: ${themeGet('text.1', '#ffffff')};
    }
  }
  @media only screen and (max-width: 767px) {
    color: ${themeGet('text.4', '#1a1a1b')};
    border: 1px solid ${themeGet('color.1', '#2ec4b6')};
    &.primary {
      background: ${themeGet('color.1', '#2ec4b6')};
    }
    &.secondary {
      background: ${themeGet('color.6', '#ffffff')};
    }
    &:hover,
    &:active,
    &:target {
      &.primary {
        background: ${themeGet('color.2', '#2ec4b6')};
        border-color: ${themeGet('color.2', '#2ec4b6')};
      }
      &.secondary {
        background: ${themeGet('color.8', '#F5F5F5')};
        color: ${themeGet('text.4', '#1a1a1b')};
        border: 1px solid ${themeGet('color.1', '#2ec4b6')};
      }
    }
  }
`;

import React from 'react';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import { isMobile } from 'react-device-detect';

import Button from '../../../elements/Button/Button';

import title from '../../../images/home_title.svg';
import titleMobile from '../../../images/home_title_mobile.svg';

import { Section, Container, Title } from './SectionMain.style';

const SectionMain = ({match}) => {
  const { t } = useTranslation();
  return (
    <Section>
      <Container>
        <ReactSVG className="home-title" src={isMobile ? titleMobile : title} />
        <Title>{t('POPEYE_HOMEPAGE_TITLE')}</Title>
        <Button
          label={t('POPEYE_HOMEPAGE_CTA')}
          onClick={() => window.open(`${process.env.REACT_APP_BOATER_URL}/${match.params.locale || 'en'}/registration`)}
        />
      </Container>
    </Section>
  )
}

export default withRouter(SectionMain);

import React from "react";
import {StyledButton, Icon} from "./Button.style";
import {IconAlign, Variant} from "./constants";

const Button = props => {
    const {
        label,
        variant,
        icon,
        iconAlign,
        medium,
        disabled,
        onClick,
        onMouseUp,
        fillWidth
    } = props;

    return (<StyledButton
        variant={variant || Variant.Filled}
        icon={!!icon}
        medium={medium}
        disabled={disabled}
        onClick={onClick}
        onMouseUp={onMouseUp}
        fillWidth={fillWidth}
    >
        {icon && iconAlign !== IconAlign.Right && <Icon src={icon} variant={variant} disabled={disabled} />}
        {label && <span>{label}</span>}
        {icon && iconAlign === IconAlign.Right && <Icon src={icon} variant={variant} disabled={disabled} />}
    </StyledButton>);
};

export default Button;

module.exports =  {
  translation: {
	"POPEYE_REGISTRATION_THIRD_STEP_COMPLETE_TITLE": "¡Felicidades!<br>Te has unido al club",
	"POPEYE_REGISTRATION_THIRD_STEP_COMPLETE_BODY": "Te invitamos a que explores Pick a Pier,<br>encuentres un puerto en tu siguiente destino y empieces a recolectar puntos.",
	"POPEYE_REGISTRATION_THIRD_STEP_COMPLETE_CTA": "Go to Pick a Pier",
	"INSERT_CODE": "Tengo un código",
	"INSERT_CODE_ERROR": "No hemos podido canjear ese código, por favor, vuelva a intentarlo",
	"POPEYE_FOOTER": "@ {{current_year}} King Features Syndicate",
	"POPEYE_HOMEPAGE_TITLE": "Presentamos el programa de fidelidad de patrones más avanzado, gratificante y sostenible",
	"POPEYE_HOMEPAGE_CTA": "Unirse al Club",
	"POPEYE_HOMEPAGE_VALUE1": "Ten acceso prioritario en los mejores puertos",
	"POPEYE_HOMEPAGE_VALUE2": "Gana puntos y canjea recompensas",
	"POPEYE_HOMEPAGE_VALUE3": "Promueve un océano más sostenible",
	"POPEYE_HOMEPAGE_DESC": "Popeye® Sail Club es el programa de fidelización exclusivo creado para los patrones con la mentalidad más abierta. Patrocinado por la tecnología de Pick a Pier y asociados de primera clase mundial, esta única membresía entre las de su tipo abre una experiencia de navegación única y gratificante.",
	"POPEYE_HOMEPAGE_RULES_TITLE": "Saluda a todo lo que has estado esperando.",
	"POPEYE_HOMEPAGE_RULE1_TITLE": "Recibe recompensas por cada navegación.",
	"POPEYE_HOMEPAGE_RULE1_DESC": "Cada transacción te hace ganar puntos que pueden canjearse por reservas y otros servicios del puerto. Cuanto antes reserves, más puntos ganarás.",
	"POPEYE_HOMEPAGE_RULE2_TITLE": "Recibe dinero mensualmente con relación a las reservas.",
	"POPEYE_HOMEPAGE_RULE2_DESC": "Recibe un crédito de 50€ mensuales que puedes aplicar en cualquier reserva de puertos a través de Pick a Pier.",
	"POPEYE_HOMEPAGE_RULE3_TITLE": "Recibe incluso más con el Estatus de Capitán.",
	"POPEYE_HOMEPAGE_RULE3_DESC": "Reservado para los patrones más apasionados, nuestro Estatus de Capitán desbloquea servicios gratuitos y acuerdos especiales en los puertos.",
	"POPEYE_HOMEPAGE_SUSTAINABILITY_TITLE": "El Juramento del Patrón Sostenible",
	"POPEYE_HOMEPAGE_SUSTAINABILITY_BODY": "En asociación con Blue Flag, pedimos a todos nuestros nuevos patrones que tomen el Juramento del Patrón Sostenible y se adhieran a prácticas ecológicas en el mar y en la costa.",
	"POPEYE_HOMEPAGE_BOTTOM": "Popeye® Sail Club actualmente es solo por invitación.",
	"FIRST_NAME_REQUIRED": "¡Hace Falta el Nombre!",
	"FIRST_NAME_INCORRECT": "¡El Nombre es incorrecto!",
	"FIRST_NAME_LENGTH": "¡El Nombre tiene que ser mayor a 2 caracteres!",
	"LAST_NAME_REQUIRED": "¡Hace Falta el Apellido!",
	"LAST_NAME_INCORRECT": "¡El Apellido es incorrecto!",
	"LAST_NAME_LENGTH": "¡El Apellido tiene que ser mayor a 2 caracteres!",
	"EMAIL_REQUIRED": "Por favor, incluye una dirección de email válida",
	"INVALID_EMAIL": "Este email no es válido. Vuelve a intentarlo.",
	"EMAIL_PLACEHOLDER": "Email",
	"FIRST_NAME": "Nombre",
	"LAST_NAME": "Apellido",
	"SIGN_UP_TERMS_LABEL": "Al marca esta casilla confirmas que has leído y aceptado nuestros <a href={{terms_of_service_link}} target=_blank title=Terms of Service>Términos del Servicio</a> y <a href={{privacy_policy_link}} target=_blank title=Pick a pier Privacy Policy>Políticas de Privacidad</a>",
	"SIGN_UP_TERMS_REQUIRED": "Hace falta aceptar los Términos y Condiciones",
	"POPEYE_REGISTRATION_FIRST_STEP_CTA": "SIGUIENTE",
	"POPEYE_REGISTRATION_SECOND_STEP_OWNER": "¿Eres propietario de un barco?",
	"POPEYE_REGISTRATION_SECOND_STEP_CTA": "SIGUIENTE",
	"BOAT_OWNER_YES": "Sí",
	"BOAT_OWNER_NO": "No",
	"VESSEL_NAME_LABEL": "Nombre del Barco",
	"VESSEL_NAME_REQUIRED": "Hace falta el nombre del barco",
	"VESSEL_NAME_MIN_LETTERS": "Debe incluir al menos 3 letras",
	"LOA_PLACEHOLDER": "Eslora",
	"VESSEL_LOA_REQUIRED": "Hace falta la Eslora",
	"VESSEL_LENGTH_ERROR": "La longitud debería ser igual a 0.1 o más",
	"VESSEL_MAX_LENGTH_ERROR": "La longitud debe ser menor a 1000",
	"BEAM_PLACEHOLDER": "Manga",
	"VESSEL_BEAM_REQUIRED": "Hace falta la Manga",
	"VESSEL_BEAM_ERROR": "La Manga debe ser igual a 0.1 o mayor",
	"VESSEL_MAX_BEAM_ERROR": "La manga debe ser menor a 1000",
	"DRAFT_PLACEHOLDER": "Calado",
	"VESSEL_DRAFT_REQUIRED": "Hace falta el Calado",
	"VESSEL_DRAFT_ERROR": "El Calado debe ser igual a 0.1 o más",
	"VESSEL_MAX_DRAFT_ERROR": "El Calado debe ser menor a 1000",
	"HOME_MARINA_LABEL": "Seleccionar Puerto de Residencia",
	"HOME_MARINA_REQUIRED": "Hace falta el Puerto de Residencia",
	"NO_RESULTS_FOUND_SEARCH_AGAIN_OR_SELECT": "No se han encontrado resultados. Asegúrate de que no haya errores o haz clic",
	"OTHER": "Otro",
	"ADD": "Añadir",
	"HOME_MARINA_NAME_LABEL": "Puerto de Residencia",
	"POPEYE_REGISTRATION_WAITLIST_TITLE": "Gracias por registrarte en la lista de espera.",
	"POPEYE_REGISTRATION_WAITLIST_BODY": "En este momento, el Popeye Sails Club es solo por invitación. Pronto estaremos disponibles a todos los patrones, y estarás entre los primeros en saberlo cuando lo hagamos.",
	"SIGNATURE_TITLE": "Dibuja tu Firma",
	"SIGNATURE_CLEAR": "Limpiar",
	"POPEYE_REGISTRATION_THIRD_STEP_BACK": "Volver",
	"SIGNATURE_CTA": "Guardar",
	"BOATER_SIGNED_UP_ERROR": "La dirección de email ya está en uso en otra cuenta.",
	"POPEYE_REGISTRATION_INSERT_CODE_PLACE_HOLDER": "¿Tienes un código de registro?",
	"POPEYE_REGISTRATION_CODE_PRIMARY_CTA": "Registrarse con un código",
	"PASSWORD_PLACEHOLDER": "Contraseña",
	"PASSWORD_LENGTH": "La contraseña debe ser mayor a 6 caracteres",
	"PASSWORD_TOO_LONG": "Su contraseña debe contener 20 caracteres como máximo",
	"PASSWORD_REQUIRED": "Por favor, escoja una contraseña",
	"POPEYE_HOMEPAGE_RULE4_TITLE": "Canjear puntos por descuentos",
	"POPEYE_HOMEPAGE_RULE4_DESC": "Aplica tus puntos ganados en tu siguiente reserva participando en puertos de Pick a Pier.",
	"POPEYE_REGISTRATION_CODE_EXPLANATION": "Para ser miembro, necesitas una invitación o un código de registro activo",
	"POPEYE_REGISTRATION_CODE_SECONDARY_CTA": "Continuar sin un código",
	"FOR_BOATERS_MENU_ITEM": "PARA PATRONES",
	"FOR_MARINAS_MENU_ITEM": "PARA PUERTOS",
	"FOR_THE_OCEAN_MENU_ITEM": "PARA EL OCÉANO",
	"LOG_IN_MENU_ITEM": "ACCEDER",
	"SIGN_UP_MENU_ITEM": "REGISTRO",
	"POPEYE_HOMEPAGE_INSPIRE": "Sé parte de una comunidad de los que ponen su poder colectivo en hacer que la navegación sea más sostenible y gratificante.",
	"PAP_LINK_TEXT": "¿Nuevo en Pick a Pier? <br>Aprende más sobre por qué los patrones están encantados con nosotros →",
	"NAVBAR_POPEYE_LABEL": "Popeye® Sail Club",
	"FOOTER_POPEYE": "Popeye® Sail Club",
	"FOOTER_COMPANY": "Empresa",
	"FOOTER_ABOUT": "Sobre Nosotros",
	"FOOTER_TEAM": "Equipo",
	"FOOTER_CAREER": "Empleo",
	"FOOTER_SOLUTIONS": "Soluciones",
	"FOOTER_MARINAS": "Para Puertos",
	"FOOTER_BOATERS": "Para Patrones",
	"FOOTER_OCEAN": "Para el Océano",
	"FOOTER_RESOURCES": "Recursos",
	"FOOTER_BLOG": "Blog",
	"FOOTER_PRESS": "Sala de Prensa",
	"FOOTER_CONTACT": "Contacto",
	"FOOTER_TERMS": "Términos de uso",
	"FOOTER_PRIVACY": "Políticas de privacidad",
	"FOOTER_GDPR": "Cumplimiento con LOPD",
	"FOOTER_SUBSCRIBE_DESC": "Suscribirse a nuestro boletín",
	"FOOTER_SUBSCRIBE_PLACEHOLDER": "Email",
	"FOOTER_SUBSCRIBE_SUCCESS": "¡Gracias por suscribirse!",
	"FOOTER_FOLLOW": "Síganos",
	"FOOTER_CTA": "Suscribirse"
}};
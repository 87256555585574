import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const CopyrightText = styled.p`
  position: absolute;
  bottom: 0;
  font-size: 12px;
  line-height: 20px;
  padding-bottom: 38px;
  color: ${themeGet('text.7', '#121A2C')};

  @media only screen and (max-width: 767px) {
    color: ${themeGet('text.1', '#ffffff')};
    padding-bottom: 20px;
  }
`;
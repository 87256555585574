import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Wrapper,
  Column,
  Title,
  Menu,
  MenuItem
} from './FooterMenu.style.js';

const FooterMenu = ({ menu, onLinkClick }) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      {menu.map((column, index) => {
        const { title, items } = column;
        return (
          <Column key={ index }>
            <Title>{t(title)}</Title>
            <Menu>
              {items.map((item, index) => {
                const { label, to, amplitude } = item;
                return (
                  <MenuItem key={ index }>
                    <a href={to || '#'} onClick={ () => onLinkClick(amplitude) } target='_blank'>
                      {t(label)}
                    </a>
                  </MenuItem>
                )
              })}
            </Menu>
          </Column>
        )
      })}
    </Wrapper>
  )
};

export default FooterMenu;

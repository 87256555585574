import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { generateID } from '../../utils/generateID';
import { InputWrapper, Error } from './Fields.style';

const Input = forwardRef(({ label, name, errors, placeholder, fieldStyles, ...rest }, ref) => {
  const { t } = useTranslation();
  const id = generateID();
  const extended = errors && (errors.type === 'max' || errors.type === 'min');

  return (
    <InputWrapper className={`input-field ${extended ? 'extended' : ''}`} style={ fieldStyles }>
      {Boolean(label) && <label htmlFor={`${id}_${name}`}>{t(label)}</label>}
      <input name={name} id={`${id}_${name}`} ref={ref} placeholder={t(placeholder)} {...rest} />
      {Boolean(errors) && <Error className="error-valiadtion">{t(errors.message)}</Error>}
    </InputWrapper>
  )
});

export default Input;
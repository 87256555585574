import credit from '../images/icon-credit.svg';
import present from '../images/icon-present.svg';
import turtle from '../images/icon-turtle.svg';

export const advantages = [
  {
    icon: credit,
    text: 'POPEYE_HOMEPAGE_VALUE1'
  },
  {
    icon: present,
    text: 'POPEYE_HOMEPAGE_VALUE2'
  },
  {
    icon: turtle,
    text: 'POPEYE_HOMEPAGE_VALUE3'
  }
]
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  padding: 21px 32px;
  margin-bottom: 59px;
  background-color: ${themeGet('color.5', '#000000')};
  @media only screen and (max-width: 767px) {
    margin-bottom: 41px;
    padding: 15px 17px;
    flex-direction: row-reverse;
    justify-content: end;
    background-color: transparent;
  }
  &.home-page-header {
    top: 0;
    position: fixed;
    z-index: 2;
    margin-bottom: 0;
    background-color: transparent;
    padding: 20px 37px;
    &.scrolled {
      background-color: ${themeGet('color.6', '#ffffff')};
      @media only screen and (max-width: 991px) {
        background-color: ${themeGet('color.13', '#F2F2F2')};
      }
    }
    @media only screen and (max-width: 1200px) {
      .header-logo {
        display: none;
      }
    }
    @media only screen and (max-width: 991px) {
      padding: 8px 14px 8px 26px;
      flex-direction: row;
      .header-logo {
        display: block;
      }
    }
    @media only screen and (max-width: 767px) {
      justify-content: space-between;
    }
  }
  img {
    max-width: 172px;
    height: 45px;
    @media only screen and (max-width: 767px) {
      max-width: 235px;
      height: auto;
    }
  }
`;

export const Menu = styled.ul`
  display: flex;
  align-items: center;
  color: ${themeGet('text.1', '#ffffff')};
  margin-left: auto;
`;

import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  img {
    max-width: 405px;
  }
  @media only screen and (max-width: 767px) {
    border-bottom: 0;
  }
  @media only screen and (max-width: 480px) {
    img {
      max-width: 310px;
    }
  }
  @media only screen and (max-width: 350px) {
    img {
      max-width: 280px;
    }
  }
`;

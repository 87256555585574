module.exports =  {
  translation: {
	"POPEYE_REGISTRATION_THIRD_STEP_COMPLETE_TITLE": "Toutes nos félicitations!<br>Vous avez rejoint le club",
	"POPEYE_REGISTRATION_THIRD_STEP_COMPLETE_BODY": "Nous vous invitons à explorer Pick a Pier,<br>à trouver une marina dans votre prochaine destination et à commencer à collecter des points.",
	"POPEYE_REGISTRATION_THIRD_STEP_COMPLETE_CTA": "Aller sur Pick a Pier",
	"INSERT_CODE": "J’ai un code",
	"INSERT_CODE_ERROR": "Nous n’avons pas pu faire correspondre ce code, veuillez réessayer",
	"POPEYE_FOOTER": "@ {{current_year}} King Features Syndicate",
	"POPEYE_HOMEPAGE_TITLE": "Nous vous présentons le plus avancé, le plus gratifiant et le plus écoresponsable des programmes de fidélité pour plaisanciers",
	"POPEYE_HOMEPAGE_CTA": "Rejoignez le club",
	"POPEYE_HOMEPAGE_VALUE1": "Obtenez un accès prioritaire aux meilleures marinas",
	"POPEYE_HOMEPAGE_VALUE2": "Gagnez des points pour échanger vos récompenses",
	"POPEYE_HOMEPAGE_VALUE3": "Encouragez un tourisme maritime plus durable",
	"POPEYE_HOMEPAGE_DESC": "Le Popeye® Sail Club est le programme de fidélité exclusif créé pour les plaisanciers les plus en avance sur leur temps. Alimenté par la technologie et les partenaires internationaux de Pick a Pier, ce programme de fidélité unique en son genre vous offre une expérience de plaisance gratifiante et sans pareille.",
	"POPEYE_HOMEPAGE_RULES_TITLE": "Venez découvrir tout ce dont vous avez toujours rêvé.",
	"POPEYE_HOMEPAGE_RULE1_TITLE": "Soyez récompensé(e) pour chaque voyage.",
	"POPEYE_HOMEPAGE_RULE1_DESC": "Chaque transaction vous permet de gagner des points qui peuvent être utilisés pour vos réservations ou vos autres services de marina. Plus vous réservez tôt, plus vous gagnez de points.",
	"POPEYE_HOMEPAGE_RULE2_TITLE": "Obtenez des récompenses d’argent mensuelles à utiliser pour vos réservations.",
	"POPEYE_HOMEPAGE_RULE2_DESC": "Obtenez un crédit mensuel de 50 € que vous pouvez utiliser pour toute réservation de marina sur Pick a Pier.",
	"POPEYE_HOMEPAGE_RULE3_TITLE": "Obtenez encore plus avec le statut Capitaine.",
	"POPEYE_HOMEPAGE_RULE3_DESC": "Réservé à nos plaisanciers les plus passionnés, notre statut de Capitaine vous permet de débloquer des services gratuits et des offres spéciales dans les marinas.",
	"POPEYE_HOMEPAGE_SUSTAINABILITY_TITLE": "Le Serment de Plaisance durable",
	"POPEYE_HOMEPAGE_SUSTAINABILITY_BODY": "En partenariat avec Blue Flag, nous demandons à tous nos nouveaux plaisanciers de prêter le Serment de Plaisance durable et d’adhérer aux pratiques écoresponsables en mer et à l’amarrage.",
	"POPEYE_HOMEPAGE_BOTTOM": "Le Popeye® Sail Club n’est pour le moment accessible que sur invitation.",
	"FIRST_NAME_REQUIRED": "Le prénom est requis !",
	"FIRST_NAME_INCORRECT": "Le prénom est incorrect !",
	"FIRST_NAME_LENGTH": "Le prénom doit faire plus de 2 caractères !",
	"LAST_NAME_REQUIRED": "Le nom de famille est requis !",
	"LAST_NAME_INCORRECT": "Le nom de famille est incorrect !",
	"LAST_NAME_LENGTH": "Le nom de famille doit faire plus de 2 caractères !",
	"EMAIL_REQUIRED": "Veuillez fournir une adresse email valide",
	"INVALID_EMAIL": "Cette adresse email est invalide. Réessayez.",
	"EMAIL_PLACEHOLDER": "Email",
	"FIRST_NAME": "Prénom",
	"LAST_NAME": "Nom de famille",
	"SIGN_UP_TERMS_LABEL": "En cochant cette case, vous confirmez que vous avez lu et que vous acceptez nos <a href={{terms_of_service_link}} target=_blank title=Terms of Service>Conditions d’utilisation</a> et notre <a href={{privacy_policy_link}} target=_blank title=Pick a pier Privacy Policy>Politique de confidentialité</a>",
	"SIGN_UP_TERMS_REQUIRED": "L’acceptation des Conditions d’utilisation est requise",
	"POPEYE_REGISTRATION_FIRST_STEP_CTA": "SUIVANT",
	"POPEYE_REGISTRATION_SECOND_STEP_OWNER": "Êtes-vous propriétaire de navire ?",
	"POPEYE_REGISTRATION_SECOND_STEP_CTA": "SUIVANT",
	"BOAT_OWNER_YES": "Oui",
	"BOAT_OWNER_NO": "Non",
	"VESSEL_NAME_LABEL": "Nom du navire",
	"VESSEL_NAME_REQUIRED": "Le nom du navire est requis",
	"VESSEL_NAME_MIN_LETTERS": "Doit comporter au moins 3 lettres",
	"LOA_PLACEHOLDER": "L(ht)",
	"VESSEL_LOA_REQUIRED": "La L(ht) est requise",
	"VESSEL_LENGTH_ERROR": "La longueur doit faire au moins 0,1",
	"VESSEL_MAX_LENGTH_ERROR": "La longueur ne doit pas dépasser 1 000",
	"BEAM_PLACEHOLDER": "Largeur",
	"VESSEL_BEAM_REQUIRED": "La largeur est requise",
	"VESSEL_BEAM_ERROR": "La largeur doit faire au moins 0,1",
	"VESSEL_MAX_BEAM_ERROR": "La largeur ne doit pas dépasser 1 000",
	"DRAFT_PLACEHOLDER": "Tirant",
	"VESSEL_DRAFT_REQUIRED": "Le tirant est requis",
	"VESSEL_DRAFT_ERROR": "Le tirant doit faire au moins 0,1",
	"VESSEL_MAX_DRAFT_ERROR": "Le tirant ne doit pas dépasser 1 000",
	"HOME_MARINA_LABEL": "Sélectionnez la marina de résidence",
	"HOME_MARINA_REQUIRED": "La marina de résidence est requise",
	"NO_RESULTS_FOUND_SEARCH_AGAIN_OR_SELECT": "Aucun résultat trouvé. Assurez-vous de ne pas avoir fait d’erreur, ou cliquez sur",
	"OTHER": "Autre",
	"ADD": "Ajouter",
	"HOME_MARINA_NAME_LABEL": "Marina de résidence",
	"POPEYE_REGISTRATION_WAITLIST_TITLE": "Merci de vous être inscrit(e) sur la liste d’attente.",
	"POPEYE_REGISTRATION_WAITLIST_BODY": "Pour le moment, le Popeye Sails Club n’est accessible que sur invitation. Nous allons bientôt le rendre accessible à tous les plaisanciers, et vous serez parmi les premiers à le savoir quand nous le ferons.",
	"SIGNATURE_TITLE": "Tracez votre signature",
	"SIGNATURE_CLEAR": "Effacer",
	"POPEYE_REGISTRATION_THIRD_STEP_BACK": "Retour",
	"SIGNATURE_CTA": "Enregistrer",
	"BOATER_SIGNED_UP_ERROR": "Cette adresse email est déjà utilisée par un autre compte.",
	"POPEYE_REGISTRATION_INSERT_CODE_PLACE_HOLDER": "Avez-vous un code d’inscription ?",
	"POPEYE_HOMEPAGE_RULE4_TITLE": "Échanger des points contre des rabais.",
	"POPEYE_HOMEPAGE_RULE4_DESC": "Échangez vos points gagnés lors de votre prochaine réservation dans les marinas participantes de Pick a Pier.",
	"POPEYE_REGISTRATION_CODE_EXPLANATION": "Pour devenir membre, il vous faut une invitation ou un code d’inscription actif",
	"POPEYE_REGISTRATION_CODE_PRIMARY_CTA": "S’inscrire avec un code",
	"POPEYE_REGISTRATION_CODE_SECONDARY_CTA": "Continuer sans code",
	"PASSWORD_PLACEHOLDER": "Mot de passe",
	"PASSWORD_REQUIRED": "Veuillez choisir un mot de passe",
	"PASSWORD_LENGTH": "Le mot de passe doit faire plus de 6 caractères",
	"PASSWORD_TOO_LONG": "Votre mot de passe ne doit pas faire plus de 20 caractères",
	"FOR_BOATERS_MENU_ITEM": "POUR LES PLAISANCIERS",
	"FOR_MARINAS_MENU_ITEM": "POUR LES MARINAS",
	"FOR_THE_OCEAN_MENU_ITEM": "POUR L’OCÉAN",
	"LOG_IN_MENU_ITEM": "SE CONNECTER",
	"SIGN_UP_MENU_ITEM": "S’INSCRIRE",
	"POPEYE_HOMEPAGE_INSPIRE": "Rejoignez la communauté de ceux qui concertent leurs efforts pour rendre la plaisance plus durable et gratifiante.",
	"PAP_LINK_TEXT": "Novice sur Pick a Pier ? <br>Venez voir pourquoi les plaisanciers nous adorent →",
	"NAVBAR_POPEYE_LABEL": "Popeye® Sail Club",
	"FOOTER_POPEYE": "Popeye® Sail Club",
	"FOOTER_COMPANY": "Entreprise",
	"FOOTER_ABOUT": "À propos de nous",
	"FOOTER_TEAM": "Équipe",
	"FOOTER_CAREER": "Carrières",
	"FOOTER_SOLUTIONS": "Solutions",
	"FOOTER_MARINAS": "Pour les marinas",
	"FOOTER_BOATERS": "Pour les plaisanciers",
	"FOOTER_OCEAN": "Pour l’océan",
	"FOOTER_RESOURCES": "Ressources",
	"FOOTER_BLOG": "Blog",
	"FOOTER_PRESS": "Presse",
	"FOOTER_CONTACT": "Nous contacter",
	"FOOTER_TERMS": "Conditions d’utilisation",
	"FOOTER_PRIVACY": "Politique de confidentialité",
	"FOOTER_GDPR": "Conformité au RGPD",
	"FOOTER_SUBSCRIBE_DESC": "Inscrivez-vous à notre newsletter",
	"FOOTER_SUBSCRIBE_PLACEHOLDER": "Email",
	"FOOTER_SUBSCRIBE_SUCCESS": "Merci pour votre inscription !",
	"FOOTER_FOLLOW": "Suivez-nous",
	"FOOTER_CTA": "S’inscrire"
}};
import React, { useContext } from 'react';
import { ThemeProvider } from 'styled-components';
import { I18nextProvider } from 'react-i18next';
import { Switch, Route, withRouter, Redirect} from 'react-router-dom';
import LayoutProvider from './providers/LayoutProvider';
import GlobalProvider from './providers/GlobalProvider';
import PopeyeProvider from './providers/PopeyeProvider';
import AuthProvider from './providers/AuthProvider';
import { PopeyeContext } from './providers/PopeyeProvider';
import i18n from './settings/i18n';

import { theme } from './settings/theme';

import Header from './components/Header/Header';
import Home from './components/Home/Home';
import SignUp from './components/SignUp/SignUp';
import Register from './components/Register/Register';
import ReferralCode from './components/ReferralCode/ReferralCode';
import Vessel from './components/Vessel/Vessel';
import WaitingList from './components/WaitingList/WaitingList';
import Congrats from './components/Congrats/Congrats';

import Copyright from './elements/Copyright/Copyright';

import AppStyle, { AppWrapper } from './App.style';

import {
  HOME_PAGE,
  SIGNUP_PAGE,
  REGISTER_PAGE,
  REFERRAL_CODE_PAGE,
  VESSEL_PAGE,
  WAITING_PAGE,
  CONGRATS_PAGE
} from './constants/routes';

export const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { boaterId } = useContext(PopeyeContext);
  return (
    <Route
      render={props => {
        return boaterId ? (
          <Component {...props} />
        ) : (
          <Redirect to={`/${props.match.params.locale}`}/>
        );
      }}
      {...rest}
    />
  );
};

const App = ({ match, location }) => {
  const isHomePage = match.url === location.pathname;

  return (
    <ThemeProvider theme={theme}>
      <AppStyle />
      <I18nextProvider i18n={i18n}>
        <LayoutProvider>
          <GlobalProvider>
            <AuthProvider>
              <PopeyeProvider>
                <AppWrapper className={isHomePage ? 'home-page-wrapper' : ''}>
                  <Header i18n={i18n} />
                  <Switch>
                    <Route
                      path={match.path + HOME_PAGE}
                      exact
                      component={Home}
                    />
                    <Route
                      path={match.path + SIGNUP_PAGE}
                      component={SignUp}
                    />
                    <ProtectedRoute
                      path={match.path + REGISTER_PAGE}
                      component={Register}
                    />
                    <ProtectedRoute
                      path={match.path + REFERRAL_CODE_PAGE}
                      component={ReferralCode}
                    />
                    <ProtectedRoute
                      path={match.path + VESSEL_PAGE}
                      component={Vessel}
                    />
                    <ProtectedRoute
                      path={match.path + WAITING_PAGE}
                      component={WaitingList}
                    />
                    <ProtectedRoute
                      path={match.path + CONGRATS_PAGE}
                      component={Congrats}
                    />
                    {/* <Route path='*' component={NotFound}></Route> */}
                  </Switch>
                  <Copyright/>
                </AppWrapper>
              </PopeyeProvider>
            </AuthProvider>
          </GlobalProvider>
        </LayoutProvider>
      </I18nextProvider>
    </ThemeProvider>
  );
}

export default withRouter(App);

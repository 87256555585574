/* eslint-disable no-console */
var diff = require('deep-diff');
var languages = require('./index');

var printCompare = (from, to) => {
  const fromLang = languages[from];
  const toLang = languages[to];

  var d;
  var subclass = '';

  console.log('----------------------------------------');
  console.log('' + from.toUpperCase() + ' -> ' + to.toUpperCase());
  console.log('----------------------------------------');
  for (d of diff(fromLang, toLang)) {
    if (d.kind !== 'E') {
      if (d.kind === 'D') {
        if (subclass !== d.path[0]) {
          if (subclass !== '') {
            console.log('},');
          }
          subclass = d.path[0];
          console.log(d.path[0] + ': {');
        }

        if (!d.path[1]) {
          for (const [ key, value ] of Object.entries(d.lhs)) {
            console.log('  ' + key + ': "' + value + '",');
          }
        } else {
          console.log('  ' + d.path[1] + ': "' + d.lhs + '",');
        }
      }
    }
  }

  if (subclass !== '') {
    console.log('},');
  }
};


printCompare('en', 'fr');
printCompare('en', 'de');
printCompare('en', 'es');
printCompare('en', 'it');
import React from "react";
import Fade from '../Transition/Fade';
import { Wrapper } from './PhoneSlide.style';

const PhoneSlide = ({ alt, src }) => {
  return (
    <Fade>
      <Wrapper>
        <img alt={alt} src={src} />
      </Wrapper>
    </Fade>
  )
}

export default PhoneSlide

import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import Select, { components } from "react-select";
import { isMobile } from 'react-device-detect';

import { generateID } from '../../utils/generateID';

import { SelectWrapper, ArrowDown, Error } from './Fields.style';

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <ArrowDown />
    </components.DropdownIndicator>
  );
};

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    height: 54,
    borderRadius: 27,
    textAlign: 'left',
    fontSize: 17,
    border: 0,
    boxShadow: 'none',
    backgroundColor: isMobile ? '#ffffff' : '#eff2f8'
  }),

  indicatorSeparator: (provided, state) => ({
    display: 'none'
  }),

  dropdownIndicator: (provided, state) => ({
    ...provided,
    padding: '8px 27px 8px 8px'
  }),

  input: (provided, state) => ({
    ...provided,
    color: '#00123D',
    paddingLeft: 10
  }),

  placeholder: (provided, state) => ({
    ...provided,
    color: '#6e82a8',
    paddingLeft: 10
  }),

  singleValue: (provided, state) => ({
    ...provided,
    paddingLeft: 10,
  }),

  menu: (provided, state) => ({
    ...provided,
    color: '#00123D',
    textAlign: 'left'
  }),

  option: (provided, state) => ({
    ...provided,
    cursor: 'pointer',
    color: '#01257A',
    backgroundColor: 'none',
  })
}

const SelectField = forwardRef(({
    label,
    name,
    placeholder,
    options,
    errors,
    selectRef,
    openMenu,
    onInputChange,
    closeMenu,
    onBlur,
    ...rest
  }, ref) => {

  const { t } = useTranslation();
  const id = generateID();

  return (
    <SelectWrapper className="select-field">
      {Boolean(label) && <label htmlFor={`${id}_${name}`}>{t(label)}</label>}
      <Select
        name={name}
        id={`${id}_${name}`}
        ref={selectRef}
        innerRef={ref}
        styles={customStyles}
        options={options}
        components={{ DropdownIndicator }}
        onFocus={openMenu}
        onBlur={() => {
          closeMenu();
          onBlur();
        }}
        onInputChange={onInputChange}
        placeholder={t(placeholder)}
        blurInputOnSelect={true}
        {...rest}
      />
      {Boolean(errors) && <Error>{t(errors.message)}</Error>}
    </SelectWrapper>
  );
})

export default SelectField;
import React from 'react';
import { isMobile } from 'react-device-detect';

import title from '../../images/title.png';
import titleMobile from '../../images/title_mobile.png';

import { Wrapper } from './PopeyeTitle.style';

const PopeyeTitle = () => {
  return (
    <Wrapper>
      <img alt='signup' src={isMobile ? titleMobile : title} />
    </Wrapper>
  )
}

export default PopeyeTitle;
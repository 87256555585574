import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const Wrapper = styled.div`
  text-align: left;
`;

export const Title = styled.h5`
  color: ${themeGet('text.11', '#121A2C')};
  font-weight: bold;
  font-size: 17px;
  line-height: 24px;
  margin-bottom: 41px;
  margin-top: 0;
  @media only screen and (max-width: 991px) {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 14px;
  }
`;

export const SubscribedText = styled.p`
  color: ${themeGet('text.11', '#121A2C')};
  font-weight: bold;
  font-size: 17px;
  line-height: 24px;
  margin-bottom: 0;
`;

export const Menu = styled.ul`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  @media only screen and (max-width: 991px) {
    margin-bottom: 0;
  }
`;

export const MenuItem = styled.li`
  height: 47px;
  padding: 0;
  margin-right: 38px;
  margin-left: 0;
  @media only screen and (max-width: 991px) {
    height: 42px;
  }
`;

export const Form = styled.form`
  display: flex;
  max-width: 100%;

  button {
    align-self: flex-start;
    margin-top: 30px;
    margin-left: 8px;
  }

  input {
    width: 100%;
    border-radius: 4px;
    height: 44px;
    box-sizing: border-box;
    padding: 10px 16px;
    color: ${themeGet('text.13', '#828282')};
    font-size: 17px;
    line-height: 24px;
    background-color: ${themeGet('color.13', '#f2f2f2')};
    border: none;
    outline: none;
  }
`;

export const Field = styled.div`
  width: 288px;
  max-width: 100%;
  position: relative;
  @media only screen and (max-width: 1440px) {
    width: 220px;
  }
  @media only screen and (max-width: 991px) {
    width: 100%;
  }
`;

export const Label = styled.p`
  margin-bottom: 8px;
  color: ${themeGet('text.11', '#121A2C')};
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
`;

export const Error = styled.span`
  position: absolute;
  top: 100%;
  font-size: 14px;
  line-height: 1.5715;
  white-space: nowrap;
  text-align: left;
  color: ${themeGet('error.0', '#FF6259')};
  display: block;
  padding: 2px 0;
  width: 100%;
  box-sizing: border-box;
`

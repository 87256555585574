import React, { useState, useContext } from 'react';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

import { GlobalContext } from '../../../providers/GlobalProvider';
import { LayoutContext } from '../../../providers/LayoutProvider';

import Button from '../../../elements/Button/Button';

import { subscribeFormSchema } from '../../../constants/schemas';

import {
  Wrapper,
  Title,
  SubscribedText,
  Menu,
  MenuItem,
  Form,
  Field,
  Label,
  Error
} from './FollowMenu.style.js';

const FollowMenu = ({ menu, onLinkClick }) => {
  const { t } = useTranslation();
  const { subscribeGuest } = useContext(GlobalContext);
  const [{ loading }] = useContext(LayoutContext);

  const [ subscribed, setSubscribed ] = useState(false);

  const { email } = subscribeFormSchema;
  const defaultValues = { email: '' }

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    reset
  } = useForm({ defaultValues });

  const onSubmit = async formProps => {
    if(loading) return;

    try {
      const { email } = formProps;
      let data = { email };
      const res = await subscribeGuest(data);
      if(res && res.subscribed) {
        setSubscribed(true);
        reset();
      } else {
        if(res && res.error_message ) {
          setError('email', {type: "manual", message: res.error_message});
        } else {
          setError('email', {type: "manual", message: "Couldn't subscribe user"});
        }
      }
    } catch (e) {
      console.error('Error subscribe', e);
    }
  }

  return (
    <Wrapper>
      <Title>{t('FOOTER_FOLLOW')}</Title>
      <Menu>
        {menu.map((item, index) => {
          const { url , icon, amplitude } = item;
          return (
            <MenuItem key={index}>
              <a href={url || '#'} onClick={ () => onLinkClick(amplitude) } target='_blank'>
                <ReactSVG src={ icon }/>
              </a>
            </MenuItem>
          )
        })}
      </Menu>
      {subscribed ? (
        <SubscribedText>{t('FOOTER_SUBSCRIBE_SUCCESS')}</SubscribedText>
      ) : (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Field>
            <Label>{t('FOOTER_SUBSCRIBE_DESC')}</Label>
            <input
              id='subscribe-email'
              placeholder={t(email.placeholder)}
              {...register('email', email.options)}
            />
            {Boolean(errors.email) && <Error>{t(errors.email.message)}</Error>}
          </Field>
          <Button
            type="submit"
            disabled={loading}
            label={t('FOOTER_CTA')}
          />
        </Form>
      )}
    </Wrapper>
  );
};

export default FollowMenu;

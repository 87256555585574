import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const FooterWrapper = styled.footer`
  width: 100%;
  box-sizing: border-box;
  background-color: ${themeGet('color.6', '#ffffff')};
  padding: 88px 40px 38px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 1440px) {
    padding: 88px 30px 38px;
  }
  @media only screen and (max-width: 991px) {
    padding: 47px 24px 40px;
  }
  @media only screen and (max-width: 767px) {
  }
  @media only screen and (max-width: 480px) {
    padding: 40px 24px;
  }
`;

export const Top = styled.div`
  width: 100%;
  max-width: 1364px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 14px;

  @media only screen and (max-width: 991px) {
    flex-direction: column;
    margin-bottom: 36px;
  }
`;

export const Logo = styled.div`
  @media only screen and (max-width: 991px) {
    margin-bottom: 38px;
    text-align: left;
  }
  a {
    width: 101px;
    height: 75px;
    @media only screen and (max-width: 767px) {
      height: 58px;
      width: 78px;
    }
  }

  img {
    width: 101px;
    height: 75px;
    @media only screen and (max-width: 767px) {
      height: 58px;
      width: 78px;
    }
  }
`;

export const Bottom = styled.div`
  width: 100%;
  max-width: 1364px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 991px) {
    flex-direction: column;
  }
`;

export const Copyright = styled.div`
  color: ${themeGet('text.13', '#828282')};
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  @media only screen and (max-width: 991px) {
    margin-bottom: 5px;
  }
`;

export default FooterWrapper;
